import React, { useEffect, useRef, useState } from 'react';
import ConfirmModal from '../components/ConfirmModal';
import ComparisonSlider from '../components/ComparisonSlider';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { loadAndDrawImage } from './modules/functions';
import coffee1 from '../assets/images/concept/헤드폰 원본.webp';
import coffee2 from '../assets/images/concept/헤드폰1.png';
import coffee3 from '../assets/images/concept/헤드폰2.png';
import '../styles/ConceptLanding.css'
// import { useCredit } from '../context/CreditContext';

const ConceptLandingInner = () => {
  const { currentUser, googleLogin } = useAuth();
  // const { freeCredits, paidCredits } = useCredit();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(null);
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const fileInputRef = useRef(null);
  const [isDragOver, setIsDragOver] = useState(false);

  // 캔버스에 드래그로 파일 넣기
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    loadImage(file);
  };

  // 클릭시 업로드 창
  const handleCanvasClick = (event) => {
    if (!image) {
      fileInputRef.current.click();
    }
  };
  
  // 드래그해서 캔버스에 업로드 관련
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    loadImage(file);
    setIsDragOver(false);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (event) => {
    setIsDragOver(false);
  };
  
  // 업로드 한 이미지 로드
  const loadImage = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const canvas = canvasRef.current;
        if (canvas) {
          const ctx = canvas.getContext('2d');
          loadAndDrawImage(ctx, canvas, img);
          const base64Image = canvas.toDataURL('image/png');
          setImage(base64Image);
          afterImageLoad(base64Image);
        }
      };
    };
    reader.readAsDataURL(file);
  };

  // 이미지 로드 완료시 비로그인이면 모달 오픈
  const afterImageLoad = () => {
    if (!currentUser) {
      setShowModal(true);
    }
  };

  // 캔버스에 업로드 했는데 로그인 상태면 컨셉이미지 페이지로
  useEffect(() => {
    if (image && currentUser) {
      navigate('/conceptgenerate', { state: { upFile: image } });
    }
  // eslint-disable-next-line
  }, [image, currentUser, navigate]);

  // 슬라이더 배열
  const imagePairs = [
    { img1: coffee1, img2: coffee2 },
    { img1: coffee3, img2: coffee2 },
    { img1: coffee3, img2: coffee1 },
    { img1: coffee2, img2: coffee1 },
    { img1: coffee2, img2: coffee3 },
    { img1: coffee1, img2: coffee3 },
  ];
  const [currentPairIndex, setCurrentPairIndex] = useState(0);

  const toggleImages = () => {
    setCurrentPairIndex((prevIndex) => (prevIndex + 1) % imagePairs.length);
    // console.log('toggle', currentPairIndex, imagePairs[currentPairIndex])
  };

  return (
    <div className='ConceptLandingInner'>
      <ConfirmModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        message="서비스를 이용하시려면"
        message1="로그인/회원가입이 필요합니다."
        marginTop="100px"
        marginTop2="70px"
        message2="로그인/회원가입 하시겠습니까?"
        yesButtonId="signup-modal-yes"
        noButtonId="signup-modal-no"
        onClose={() => {
          setShowModal(false);
          window.location.reload();
        }}
        onConfirm={() => {
          googleLogin();
          setShowModal(false);
        }}
      />

      <div className='container'>
        <div className='intro-area'>
          <div className='mershikkeng'>
            <ComparisonSlider
              bg1color='black'
              bg2color='black'
              img1={imagePairs[currentPairIndex].img1}
              img2={imagePairs[currentPairIndex].img2}
              autoSlide
              onSlideEnd={toggleImages}
            />
          </div>
          <h1><span style={{ color: '#F8A80E'}}>AI 디자이너</span>가 만들어주는 <br/><span style={{ fontSize: '1.6em'}}>컨셉 디자인</span></h1>
          <h2>나만의 제품으로 <span style={{ color: '#F8A80E'}}>새로운 배경</span>을 입혀보세요</h2>
        </div>

        <div className='upload-area'>
          <div className='canvas-area' ref={containerRef}>
            <input type="file" ref={fileInputRef} onChange={handleFileChange} accept="image/*" style={{ display: 'none' }} id='concept-upload' />
            <canvas ref={canvasRef} width="512" height="512" id='landingCanvas'
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onClick={handleCanvasClick}
              style={{ border: isDragOver && '14px dashed rgba(50, 120, 200, 0.8)', backgroundImage: image && 'none' }}>
            </canvas>
            <button onClick={handleCanvasClick} className='getStart' id='concept-getStart'>시작하기</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConceptLandingInner;
