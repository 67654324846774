const DEPLOY_PREFIX= 'https://robobrush-backend.azurewebsites.net/';
// const DEV_PREFIX= 'https://3c60-1-223-77-27.ngrok-free.app/';

const config = {

  API_VERSION: 'api/v0/',

  REMOVE_BG_URL: 'https://robobrush-ai-h8hehpc7h2hjbxfr.eastus-01.azurewebsites.net/api/v0/remove-background/',
  // REMOVE_BG_URL: 'https://1652119112d2a00a.ngrok.app/api/v1/remove-background/',
  GOOGLE_CLIENT_ID: '862030905871-nekhvmskogdhci7p8hscar8naof1ji84.apps.googleusercontent.com',

  ADMIN_GET_USERS:          DEPLOY_PREFIX+'admin/get_users',
  ADMIN_HISTORY:            DEPLOY_PREFIX+'admin/get_all_images',
  ADMIN_IMAGES_BY_SUB:      DEPLOY_PREFIX+'admin/get_user_images',
  ANALYZE_URL:              DEPLOY_PREFIX+'api/analyze_images',
  CONCEPT_GEN_URL:          DEPLOY_PREFIX+'api/concept_gen',
  CREDIT_REFRESH_URL:       DEPLOY_PREFIX+'user/get_credits',
  LOGIN_ENDPOINT:           DEPLOY_PREFIX+'user/login',
  PORTONE_CHECK_VAL:        DEPLOY_PREFIX+'validate_payment/',
  PROMPT_GEN_APIURL:        DEPLOY_PREFIX+'api/robobrush/',
  REF_GEN_APIURL:           DEPLOY_PREFIX+'api/get-reference',
  HISTORY_REGEN_APIURL:     DEPLOY_PREFIX+'api/re-generate/',
  HISTORY_URL_TO_B64_URL:   DEPLOY_PREFIX+'image/start_edit/',
  MASKED_GEN_URL:           DEPLOY_PREFIX+'api/canvas-generate/',
  USER_GET_USER:            DEPLOY_PREFIX+'user/get_user/',
  USER_UPDATE:              DEPLOY_PREFIX+'user/update/',
  USER_DELETE:              DEPLOY_PREFIX+'user/delete/',
  
  // ADMIN_GET_USERS:          DEV_PREFIX+'admin/get_users',
  // ADMIN_HISTORY:            DEV_PREFIX+'admin/get_all_images',
  // ADMIN_IMAGES_BY_SUB:      DEV_PREFIX+'admin/get_user_images',
  // ANALYZE_URL:              DEV_PREFIX+'api/analyze_images',
  // CONCEPT_GEN_URL:          DEV_PREFIX+'api/concept_gen',
  // CREDIT_REFRESH_URL:       DEV_PREFIX+'user/get_credits',
  // LOGIN_ENDPOINT:           DEV_PREFIX+'user/login',
  // PORTONE_CHECK_VAL:        DEV_PREFIX+'validate_payment/',
  // PROMPT_GEN_APIURL:        DEV_PREFIX+'api/robobrush/',
  // REF_GEN_APIURL:           DEV_PREFIX+'api/get-reference',
  // HISTORY_REGEN_APIURL:     DEV_PREFIX+'api/re-generate/',
  // HISTORY_URL_TO_B64_URL:   DEV_PREFIX+'image/start_edit/',
  // MASKED_GEN_URL:           DEV_PREFIX+'api/canvas-generate/',
  // USER_GET_USER:            DEV_PREFIX+'user/get_user/',
  // USER_UPDATE:              DEV_PREFIX+'user/update/',
  // USER_DELETE:              DEV_PREFIX+'user/delete/',


};

export default config;
