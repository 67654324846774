import React, { useState, useEffect } from 'react';
import AdminNavBar from '../components/adminNavBar';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { handleAxios } from '../components/modules/AxiosModule';
import Alerts from '../Alerts';
import LoadingModal from '../components/LoadingModal';

const AdminMemberList = () => {
  const apiURL = config.ADMIN_GET_USERS;

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });

  const { currentUser, authloading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authloading && !currentUser.isAdmin) {
      Alerts.wrongAccess();
      navigate('/');
    }
  // eslint-disable-next-line
  }, [currentUser, authloading]);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);

      try {
        const storedToken = JSON.parse(localStorage.getItem('token'));
        
        if (storedToken && storedToken.token) {
          const response = await handleAxios("get", apiURL);

          if (Array.isArray(response.data)) {
            setUsers(response.data);
          } else {
            console.error('Data is not an array', response.data);
          }
        } else {
          console.error('No token found');
        }
      } catch (error) {
        console.error('Fetching users failed:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [apiURL]);

  const handleUserClick = async (user) => {
    const apiHistory = `${config.ADMIN_IMAGES_BY_SUB}/${user.sub}`
    try {
      const storedToken = JSON.parse(localStorage.getItem('token'));
      if (storedToken && storedToken.token) {
        const response = await handleAxios("get", apiHistory);
        navigate('/admin/userpage', {state: {fetchedData: response.data, userData: user}})
      }
    } catch (error) {
      console.error('Error fetching data for sub:', user.sub, error);
    }
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? '▲' : '▼';
    }
    return '';
  };

  return (
    <div className="mt-[150px]">
      <AdminNavBar />
      {isLoading && <LoadingModal />}
      <div className="mx-auto mt-[50px] w-11/12">
        <table className="table-auto w-full border border-gray-300 rounded-lg overflow-hidden">
          <thead className="bg-gray-800">
            <tr className="bg-[#f4f4f4d6] text-[#333333]">
              <th></th>
              <th className="border border-gray-300 p-3 text-left cursor-pointer hover:bg-[#808080] hover:text-white" onClick={() => requestSort('nickname')}>
                Name {getSortIndicator('nickname')}
              </th>
              <th className="border border-gray-300 p-3 text-left cursor-pointer hover:bg-[#808080] hover:text-white" onClick={() => requestSort('sub')}>
                Sub {getSortIndicator('sub')}
              </th>
              <th className="border border-gray-300 p-3 text-left cursor-pointer hover:bg-[#808080] hover:text-white" onClick={() => requestSort('email')}>
                Email {getSortIndicator('email')}
              </th>
              <th className="border border-gray-300 p-3 text-left hidden md:table-cell cursor-pointer hover:bg-[#808080] hover:text-white" onClick={() => requestSort('created_at')}>
                Created {getSortIndicator('created_at')}
              </th>
              <th className="border border-gray-300 p-3 text-left hidden md:table-cell cursor-pointer hover:bg-[#808080] hover:text-white" onClick={() => requestSort('last_login')}>
                Last Login {getSortIndicator('last_login')}
              </th>
            </tr>
          </thead>
          <tbody className="bg-transparent text-white text-[14px]">
            {sortedUsers.map((user) => (
              <tr
                key={user.sub}
                className="hover:bg-gray-600 cursor-pointer transition duration-300"
                onClick={() => handleUserClick(user)}
              >
                <td className="border border-gray-300 p-[5px] w-[60px] text-center">
                  <img
                    src={user.picture}
                    alt={user.name}
                    className="w-[40px] h-[40px] rounded-full"
                  />
                </td>
                <td className="border border-gray-300 p-[5px]">{user.nickname}</td>
                <td className="border border-gray-300 p-[5px]">{user.sub}</td>
                <td className="border border-gray-300 p-[5px]">{user.email}</td>
                <td className="border border-gray-300 p-[5px] hidden md:table-cell">{user.created_at}</td>
                <td className="border border-gray-300 p-[5px] hidden md:table-cell">{user.last_login}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminMemberList;
