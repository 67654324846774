import React, { useEffect } from 'react';
import { Stage, Layer, Image as KonvaImage, Transformer } from 'react-konva';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndoAlt, faRedoAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { makeNewImage } from './modules/functions';
import '../styles/KonvaCanvas.css'

const KonvaCanvas = ({ setImages, images, setUndoStack, undoStack, setRedoStack, redoStack,
  setSelectedId, selectedId, transformerRef, stageRef, canvasWidth, canvasHeight }) => {

  // redo undo 히스토리
  const updateImages = (newImages) => {
    setUndoStack([...undoStack, images]);
    setImages(newImages);
    setRedoStack([]);
  };

  const undo = () => {
    if (undoStack.length > 0) {
      const newUndoStack = [...undoStack];
      const previousState = newUndoStack.pop();
      setRedoStack([...redoStack, images]);
      setImages(previousState);
      setUndoStack(newUndoStack);
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const newRedoStack = [...redoStack];
      const nextState = newRedoStack.pop();
      setUndoStack([...undoStack, images]);
      setImages(nextState);
      setRedoStack(newRedoStack);
    }
  };
  

  // 캔버스 내용물 조작시 상태 저장
  const handleTransformChange = (newAttrs, id) => {
    const newImages = images.map((img) => {
      if (img.id === id) {
        return { ...img, ...newAttrs };
      }
      return img;
    });
    updateImages(newImages);

    // const stage = stageRef.current;
    // if (stage) {
    //   const imageDataUrl = stage.toDataURL({
    //     mimeType: 'image/png',
    //     quality: 1,
    //     pixelRatio: 3
    //   });
    //   updateSelectedProduct(imageDataUrl);
    // }
  };


  // 이미지 클릭하면 해당 이미지 선택
  const handleImageClick = (id) => {
    setSelectedId(id);
  };

  // 레이어 삭제
  const deleteLayer = () => {
    setUndoStack([...undoStack, images]);
    setRedoStack([]);

    setImages(prev => prev.filter(img => img.id !== selectedId));
    setSelectedId(null);
    transformerRef.current.nodes([]);
    transformerRef.current.getLayer().batchDraw();
  };

  // 선택 해제
  const clearSelection = (e) => {
    // 이미지 레이어나 트랜스포머가 아닌 다른 곳을 클릭했을 때
    if (e.target === e.target.getStage()) {
      setSelectedId(null);
      transformerRef.current.nodes([]);
      transformerRef.current.getLayer().batchDraw();
    }
  };

  // 드래그 드랍으로 업로드
  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();

    reader.onload = () => {
      const img = new window.Image();
      img.src = reader.result;
      img.onload = () => {
        setUndoStack([...undoStack, images]);
        setRedoStack([]);
        setImages((prevImages) => [
          ...prevImages,
          makeNewImage({
            canvasWidth: canvasWidth,
            canvasHeight: canvasHeight,
            image: img, 
            imageId: prevImages.length.toString()
          })
        ]);
      };
    };

    if (files.length > 0) {
      reader.readAsDataURL(files[0]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const stage = stageRef.current;
    stage.container().addEventListener('dragover', handleDragOver);
    stage.container().addEventListener('drop', handleDrop);

    return () => {
      stage.container().removeEventListener('dragover', handleDragOver);
      stage.container().removeEventListener('drop', handleDrop);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedId && transformerRef.current) {
      const node = stageRef.current.findOne(`#${selectedId}`);
      if (node) {
        transformerRef.current.nodes([node]);
        transformerRef.current.getLayer().batchDraw();
      } else {
        transformerRef.current.nodes([]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, images]);


  return (
    <>
      <div className='KonvaCanvas'>
        <Stage
          width={canvasWidth}
          height={canvasHeight}
          ref={stageRef}
          onClick={clearSelection}
          className='KonvaStage'
        >
          <Layer>
            {images.map((img, index) => (
              <KonvaImage
                key={index}
                image={img.image}
                x={img.x}
                y={img.y}
                width={img.width}
                height={img.height}
                id={img.id}
                draggable
                onClick={() => handleImageClick(img.id)}
                onTap={() => handleImageClick(img.id)}
                onTransformEnd={(e) => {
                  // 변환 종료시 업데이트
                  const node = e.target;
                  const scaleX = node.scaleX();
                  const scaleY = node.scaleY();
                  
                  // 노드의 스케일을 반영한 최종 위치와 크기를 계산
                  const newX = node.x();
                  const newY = node.y();
                  const newWidth = node.width() * scaleX;
                  const newHeight = node.height() * scaleY;
                  
                  // 변형 이후 스케일을 1로 재설정
                  node.scaleX(1);
                  node.scaleY(1);
                  
                  handleTransformChange({
                    x: newX,
                    y: newY,
                    width: newWidth,
                    height: newHeight,
                  }, img.id);
                }}
                onDragStart={(e) => {
                  // 드래그 시작 시점에서 현재 이미지 상태를 undo 스택에 추가
                  setUndoStack([...undoStack, images]);
                  setRedoStack([]);  // 드래그 시작할 때 redo 스택 초기화
                }}
                onDragEnd={(e) => {
                  // 드래그 종료 시점에서 새 위치로 이미지 상태 업데이트
                  const node = e.target;
                  const newImages = images.map(img => {
                    if (img.id === node.id()) {
                      return { ...img, x: node.x(), y: node.y() };
                    }
                    return img;
                  });
                  setImages(newImages);
                }}
              />
            ))}
            <Transformer ref={transformerRef} />
          </Layer>
        </Stage>
      </div>
      <div className='Konva-buttons'>
        <button onClick={undo}><FontAwesomeIcon icon={faUndoAlt} size='2x' /></button>
        <button onClick={redo}><FontAwesomeIcon icon={faRedoAlt} size='2x' /></button>
        <button onClick={deleteLayer}><FontAwesomeIcon icon={faTrash} size='2x' /></button>
      </div>
    </>
  );
};

export default KonvaCanvas;
