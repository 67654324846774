import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const SearchResult = ({ images, onImageSelect, loadMoreImages, totalHits, selectedImages }) => {
  const loadMoreRef = useRef(null);
  const [showScrollTop, setShowScrollTop] = useState(false);

  // 스크롤 위치에 따른 스크롤 탑 버튼 표시
  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScrollTop && window.scrollY > 900) {
        setShowScrollTop(true);
      } else if (showScrollTop && window.scrollY <= 900) {
        setShowScrollTop(false);
      }
    };

    const loop = () => {
      checkScrollTop();
      requestAnimationFrame(loop);
    };

    loop();

    return () => {
      cancelAnimationFrame(loop);
    };
  }, [showScrollTop]);

  // 무한 스크롤 기능을 위한 Intersection Observer 설정
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && images.length < totalHits) {
          loadMoreImages();
        }
      },
      { threshold: 0.1 }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
    return () => observer.disconnect();
  }, [images, totalHits, loadMoreImages]);

  // 스크롤 탑 버튼 클릭 시 페이지 상단으로 이동
  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
  
  return (
    <div className="grid grid-cols-1 sm:grid-cols-4 gap-[10px] mx-auto my-[25px] justify-center items-center text-center">
      {images.map((image, index) => (
        <div
          key={`${image.id}-${index}`}
          className={`relative group max-w-[400px] h-[400px] mx-auto justify-center items-center box-border ${selectedImages.includes(image) ? 'border-[6px] border-white opacity-80' : ''}`}
          onClick={() => onImageSelect(image)}
        >
          <img className="max-w-full max-h-full object-contain bg-white" src={image.webformatURL} alt={image.tags} />
          <div className="absolute bottom-[0] left-[-4px] text-right text-white font-bold text-[11px] opacity-50">
            Powered by PIXABAY
          </div>
          <div className="absolute top-[0] left-0 text-white text-[15px] bg-black bg-opacity-40 p-[5px] hidden group-hover:block">
            {image.tags.split(', ').join(', ')}
          </div>
        </div>
      ))}
      {images.length < totalHits && (
        <div ref={loadMoreRef} className="w-full text-center my-[100px] mx-auto">
          <button onClick={loadMoreImages} className="w-[200px] py-[15px] text-[18px] font-bold border border-white text-white bg-transparent hover:border-[#597EDC] hover:text-[#597EDC] transform hover:scale-[0.97] transition">
            더 불러오기...
          </button>
        </div>
      )}
      {showScrollTop && (
        <div className="fixed right-[20px] bottom-[185px] text-white text-[17px] font-bold border border-white rounded-[15px] p-[10px] z-[100] hover:border-[#597EDC] hover:text-[#597EDC] transform hover:scale-[0.97] transition cursor-pointer" onClick={scrolltop}>
          <FontAwesomeIcon icon={faArrowUp} />
        </div>
      )}
    </div>
  );
}

export default SearchResult;