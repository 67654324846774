import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import config from '../config';
import { handleAxios } from '../components/modules/AxiosModule';
import { useNavigate } from 'react-router-dom';

const CreditContext = createContext();

export function useCredit() {
  return useContext(CreditContext);
}

export const CreditProvider = ({ children }) => {
  const navigate = useNavigate()
  const { currentUser } = useAuth();
  const [freeCredits, setFreeCredits] = useState(0);
  const [paidCredits, setPaidCredits] = useState(0);
  const [creditLoading, setCreditLoading] = useState(true);
  const totalCredits = freeCredits + paidCredits
  
  const checkCredits = (count) => {
    if (totalCredits < count) {
      alert(`크레딧이 부족합니다. 해당 기능은 ${count}개의 크레딧을 소모합니다. 충전 페이지로 이동합니다.`);
      navigate('/pricing');
      return false;
    }
    return true;
  }
  

  // 크레딧 조회
  const fetchCredits = async () => {
    if (!currentUser) return;

    setCreditLoading(true);
    try {
      const apiURL = config.CREDIT_REFRESH_URL;
      const response = await handleAxios("get", apiURL);

      if (response.data) {
        setFreeCredits(response.data.free_credits);
        setPaidCredits(response.data.paid_credits);
      } else {
        console.error('Invalid response format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching credits:', error.message);
    } finally {
      setCreditLoading(false);
    }
  };

  // 유저 상태 변화(로그인) 감지 될 때마다 크레딧 조회 호출
  useEffect(() => {
    if (currentUser) {
      fetchCredits();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const value = {
    freeCredits,
    paidCredits,
    checkCredits,
    totalCredits,
    fetchCredits,
    creditLoading,
  };

  return (
    <CreditContext.Provider value={value}>
      {children}
    </CreditContext.Provider>
  );
};