import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { addDoc, collection, deleteDoc, doc, getCountFromServer, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore';
import firestore from '../firebaseConfig';
import { deleteObject, getStorage, ref } from 'firebase/storage';

import '../styles/admin.css'
import AdminNavBar from '../components/adminNavBar';
import Alerts from '../Alerts';


function AdminPrompt() {
  const { currentUser, authloading } = useAuth();
  const navigate = useNavigate();

  const [selectedRating, setSelectedRating] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [lastVisible, setLastVisible] = useState(null); // 마지막 문서의 스냅샷을 저장하기 위한 상태
  const [isMoreData, setIsMoreData] = useState(true); // 추가 데이터가 있는지 여부를 추적하기 위한 상태
  const [totalCount, setTotalCount] = useState(0); // 총 데이터 수를 저장하기 위한 상태

  useEffect(() => {
    if (!authloading && !currentUser.isAdmin) {
      Alerts.wrongAccess();
      navigate('/');
    }
  // eslint-disable-next-line
  }, [currentUser, authloading]);

  const fetchRatingData = async (rating, isInitial) => {
    if (isInitial) setLoading(true);
    const q = query(
      collection(firestore, `Rating/PromptRating/rating${rating}`),
      orderBy('date', 'desc'),
      limit(10),
      ...(lastVisible && !isInitial ? [startAfter(lastVisible)] : [])
    );
    const querySnapshot = await getDocs(q);
    const fetchedData = [];
    querySnapshot.forEach((doc) => {
      fetchedData.push({ id: doc.id, ...doc.data() });
    });

    // 초기 로드 시 데이터 설정, 추가 로드 시 기존 데이터에 추가
    setData(isInitial ? fetchedData : [...data, ...fetchedData]);
    // 마지막 문서 스냅샷 업데이트
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setIsMoreData(!querySnapshot.empty && querySnapshot.docs.length === 10);
    setLoading(false);

    // 총 데이터 수 업데이트
    const countSnapshot = await getCountFromServer(collection(firestore, `Rating/PromptRating/rating${rating}`));
    setTotalCount(countSnapshot.data().count);
  };

  useEffect(() => {
    if (selectedRating !== null) {
      fetchRatingData(selectedRating, true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRating]);

  useEffect(() => {
    const handleScroll = () => {
      // window.innerHeight: 현재 보이는 창의 높이
      // document.documentElement.scrollTop: 현재 스크롤된 높이
      // document.documentElement.offsetHeight: 전체 페이지의 높이
      const distanceFromBottom = document.documentElement.offsetHeight - (window.innerHeight + document.documentElement.scrollTop);
      
      // 스크롤이 하단 800px 이내에 도달하면 데이터 로드
      if (distanceFromBottom < 800 && isMoreData) {
        fetchRatingData(selectedRating, false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRating, lastVisible, isMoreData, data]);

  const selectRating = (rating) => {
    setSelectedRating(rating);
    setData([]); // 레이팅 변경 시 데이터 초기화
    setLastVisible(null); // 마지막 문서 스냅샷 초기화
    setIsMoreData(true); // 추가 데이터 여부 초기화
  };

  const storage = getStorage();

  const handleDelete = (docId, selectedRating) => {
    const isConfirmed = window.confirm("정말로 삭제하겠습니까?");
    if (isConfirmed) {
      // 비밀번호를 입력받는 추가적인 확인 단계
      const password = prompt("비밀번호를 입력해주세요:");
      const correctPassword = "나는빡빡이다";
      
      if (password === correctPassword) {
        deleteDocumentAndImage(docId, selectedRating);
      } else {
        alert("비밀번호가 틀렸습니다. 삭제가 취소되었습니다.");
      }
    } else {
      // console.log("삭제가 취소되었습니다.");
    }
  };
  

  const deleteDocumentAndImage = async (docId, selectedRating) => {
    try {
      // Firestore에서 문서 삭제
      await deleteDoc(doc(firestore, `Rating/PromptRating/rating${selectedRating}`, docId));
      // console.log("Document successfully deleted!");
  
      // Firebase Storage에서 이미지 삭제
      const imageRef = ref(storage, `PromptRating/Rating${selectedRating}/${docId}`);
      await deleteObject(imageRef);
      // console.log("Image successfully deleted!");
      setData(currentData => currentData.filter(item => item.id !== docId));
      setTotalCount(totalCount - 1); // 총 데이터 수 감소
    } catch (error) {
      console.error("Error removing document and image: ", error);
    }
  };

  // 이미지 스크롤 리스트에 추가
  const addToScroll = async (prompt, rating, imageData) => {
    try {
      await addDoc(collection(firestore, "PromptScrolls"), { prompt, rating, imageData });
      alert("성공적으로 추가되었습니다!");
      fetchPromptScrollsData(); // 추가 후 PromptScrolls 데이터 다시 불러오기
    } catch (error) {
      alert("추가하는 도중 오류가 발생했습니다: ", error);
    }
  };

  // 중복 감지, 삭제
  const [promptScrollsData, setPromptScrollsData] = useState([]);

  const fetchPromptScrollsData = async () => {
    const querySnapshot = await getDocs(collection(firestore, "PromptScrolls"));
    const fetchedData = querySnapshot.docs.map(doc => doc.data().imageData);
    setPromptScrollsData(fetchedData);
  };

  useEffect(() => {
    fetchPromptScrollsData();
  }, []);

  const removeFromScroll = async (imageData) => {
    const isConfirmed = window.confirm("정말로 삭제하시겠습니까?");
    if (!isConfirmed) {
      return; // 사용자가 취소하면 함수를 종료합니다.
    }
  
    const querySnapshot = await getDocs(query(collection(firestore, "PromptScrolls"), where("imageData", "==", imageData)));
    querySnapshot.forEach(async (document) => {
      await deleteDoc(doc(firestore, "PromptScrolls", document.id));
    });
    // 상태 업데이트로 UI 반영
    setPromptScrollsData(currentData => currentData.filter(item => item !== imageData));
  };

  return (
    <>
      <AdminNavBar />
      <section className='prompt'>
        {[0, 1, 2, 3, 4, 5].map((rating) => (
          <button
            key={rating}
            onClick={() => selectRating(rating)}
            className={selectedRating === rating ? 'active' : ''}
          >
            {rating}점
          </button>
        ))}

        <div style={{ color: 'white', margin: 10, fontSize: 20}}>
          <p>총 데이터 수: {totalCount}</p>
        </div>

        <div className='list'>
          {loading ? (
            <p>데이터를 불러오는 중...</p>
          ) : (
            data.map((item) => (
              <div className='data' key={item.id}>
                <img src={item.imageData} alt="Rating Preview" 
                 className={promptScrollsData.includes(item.imageData) ? 'highlight' : ''} />
                <div className='data-info'>
                  <div>
                    <p>Prompt: {item.prompt}</p>
                    <p>Phone: {item.phone}</p>
                    <p>E-Mail: {item.email}</p>
                    <p>Date: {item.date}</p>
                    {currentUser && currentUser.isAdmin && (
                      <button onClick={() => handleDelete(item.id, selectedRating)}>삭제</button>
                    )}
                    {promptScrollsData.includes(item.imageData) ? (
                      <button onClick={() => removeFromScroll(item.imageData)}>스크롤 목록에서 제거</button>
                    ) : (
                      <button onClick={() => addToScroll(item.prompt, selectedRating, item.imageData)}>스크롤에 추가</button>
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </section>
    </>
  );
};
export default AdminPrompt;
