import React from 'react'
import '../styles/ConceptLanding.css'
import ConceptLandingInner from '../components/ConceptLandingInner';

const ConceptGenLanding = () => {


  return (
    <div className='ConceptLanding'>
      <ConceptLandingInner />
    </div>
  )
}

export default ConceptGenLanding