import * as React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import './firebaseConfig';
import { HelmetProvider } from "react-helmet-async";
import { GoogleOAuthProvider } from "@react-oauth/google";
import config from "./config";

const client = config.GOOGLE_CLIENT_ID;

createRoot(document.getElementById("root")).render(
<React.StrictMode>
  <GoogleOAuthProvider clientId={client}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </GoogleOAuthProvider>
</React.StrictMode>
);
