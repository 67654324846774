// Modal.jsx
import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed bottom-36 inset-0 
                  flex items-center justify-center
                  z-10">
      <div className="bg-[#111820] px-8 py-12 rounded-lg shadow-xl 
                    max-w-[450px] w-3/4 mx-4 relative">
        <button
          onClick={onClose}
          className="absolute right-8 top-14 hover:text-red-600 text-white font-bold rounded"
        >
          <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className='text-base max-md:text-sm'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;