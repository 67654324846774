// b64 뒤쪽 문자열만 획득
const stripBase64Prefix = (base64Data) => {
  const prefix = /^data:image\/[a-z]+;base64,/;
  return base64Data.replace(prefix, "");
};

//make New Image
const makeNewImage = ({
    canvasWidth = null,
    canvasHeight = null,
    selectedImage = null,
    croppedImage = null,
    url = '',
    image,
    id = null
  }) => {
    
  if(!selectedImage) {
    const scaleX = canvasWidth / image.width;
    const scaleY = canvasHeight / image.height;
    const scale = Math.min(scaleX, scaleY);

    const newImage = {
      image: image,
      url: url,
      x: (canvasWidth - image.width * scale) / 2,
      y: (canvasHeight - image.height * scale) / 2,
      width: image.width * scale,
      height: image.height * scale,
      id: id,
    };
    return newImage;
  }
  else if(url === '') {
    const scaleX = selectedImage.width / image.width;
    const scaleY = selectedImage.height / image.height;
    const scale = Math.min(scaleX, scaleY);

    // 새 이미지의 크기를 조정
    const newWidth = croppedImage.width * scale;
    const newHeight = croppedImage.height * scale;

    // 새 이미지 객체를 생성
    const newImage = {
      image: croppedImage,
      url: url,
      x: selectedImage.x + (selectedImage.width - newWidth) / 2,  // 중앙 정렬
      y: selectedImage.y + (selectedImage.height - newHeight) / 2,
      width: newWidth,
      height: newHeight,
      id: id
    };
    return newImage;
  }
  else {
    const scaleX = selectedImage.width / image.width;
    const scaleY = selectedImage.height / image.height;
    const scale = Math.min(scaleX, scaleY);

    // 새 이미지의 크기를 조정
    const newWidth = croppedImage.width * scale;
    const newHeight = croppedImage.height * scale;

    // 새 이미지 객체를 생성
    const newImage = {
      image: croppedImage,
      url: url,
      x: selectedImage.x + (selectedImage.width - newWidth) / 2,  // 중앙 정렬
      y: selectedImage.y + (selectedImage.height - newHeight) / 2,
      width: newWidth,
      height: newHeight,
      id: id
    };
    return newImage;
  }
};

const loadAndDrawImage = (context, canvas, image) => {
  const scale = Math.min(canvas.width / image.width, canvas.height / image.height);
  const imgWidth = image.width * scale;
  const imgHeight = image.height * scale;
  context.clearRect(0, 0, canvas.width, canvas.height); // 기존 캔버스 초기화
  context.drawImage(image, 0, 0, imgWidth, imgHeight);

  return;
}

function cropImage(image, callback) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  canvas.width = image.width;
  canvas.height = image.height;

  context.drawImage(image, 0, 0);

  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  let minX = canvas.width, minY = canvas.height, maxX = 0, maxY = 0;

  // 이미지의 픽셀을 분석하여 실제 내용이 있는 영역 찾기
  for (let y = 0; y < canvas.height; y++) {
    for (let x = 0; x < canvas.width; x++) {
      const alpha = data[(y * canvas.width + x) * 4 + 3];
      if (alpha > 0) {
        minX = Math.min(minX, x);
        maxX = Math.max(maxX, x);
        minY = Math.min(minY, y);
        maxY = Math.max(maxY, y);
      }
    }
  }

  const croppedWidth = maxX - minX + 1;
  const croppedHeight = maxY - minY + 1;

  // 실제 내용이 있는 영역으로 이미지를 크롭
  canvas.width = croppedWidth;
  canvas.height = croppedHeight;
  context.clearRect(0, 0, croppedWidth, croppedHeight);
  context.drawImage(image, minX, minY, croppedWidth, croppedHeight, 0, 0, croppedWidth, croppedHeight);

  canvas.toBlob(blob => {
    callback(blob);  // 크롭된 이미지 데이터를 콜백을 통해 반환
  });
}

export { stripBase64Prefix, makeNewImage, loadAndDrawImage, cropImage }