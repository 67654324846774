const Alerts = {

  noCredits: (error) => {
    console.error(error);
    alert("크레딧이 부족합니다. 결제 페이지로 이동합니다.");
  },

  invalidRequest: (error) => {
    console.error(error);
    alert("요청이 잘못되었습니다.");
  },

  loadingFailed: (error) => {
    console.error(error);
    alert("데이터를 불러오는 중 에러가 발생했습니다. 잠시 후 다시 시도해주세요.");
  },

  generateFailed: (error) => {
    console.error(error);
    alert("이미지를 생성하는 도중 에러가 발생했습니다. 문제가 반복되면 관리자에게 문의해주세요.");
  },

  imgLoadFailed: (error) => {
    console.error(error);
    alert("이미지를 로드하는 도중 에러가 발생했습니다. 문제가 반복되면 관리자에게 문의해주세요.");
  },

  fillTheInput: () => {
    alert("검색어 혹은 프롬프트를 입력해주세요.");
  },

  noSymbols: () => {
    alert("공백 및 특수문자는 사용하실 수 없습니다.");
  },

  onlyDesktop: () => {
    alert("이 기능은 데스크탑에서 최적화 되어있습니다. 데스크탑에서 이용해주세요.");
  },

  copySuccess: () => {
    alert("이미지를 클립보드에 복사했습니다.");
  },

  copyFailed: (error) => {
    console.error(error)
    alert("이미지 복사에 실패했습니다.");
  },

  wrongAccess: () => {
    alert("잘못된 경로로 접근했습니다.");
  },

  needImgSelect: () => {
    alert("이미지를 클릭해서 선택해주세요.");
  },

  noStage: () => {
    alert("캔버스가 초기화되지 않았습니다. 다시 시도해주세요.");
  },

  bgRemoveError: (error) => {
    console.error(error)
    alert("배경 제거 중 오류가 발생했습니다. 다시 시도해주세요.")
  },

  imgAnalError: (error) => {
    console.error(error)
    alert("이미지 분석 중 오류가 발생했습니다. 다시 시도해주세요.")
  },

  NSFW: () => {
    alert("이미지 생성 도중 오류가 발생했거나 부적절한 이미지를 감지했습니다. \n문제가 계속될 경우 관리자에게 문의하세요.")
  },

  downloadError: (error) => {
    console.error(error)
    alert("이미지를 다운로드 하는 도중 오류가 발생했습니다.")
  },

  needFillInput: (error) => {
    console.error(error)
    alert("입력되지 않은 정보가 있습니다. 정보를 올바르게 채워주세요.")
  },

  selectLimit: (count) => {
    alert(`이미지는 ${count}개까지 선택할 수 있습니다.`)
  },

  needSelect: (count) => {
    alert(`이미지를 ${count}개 이상 선택해주세요.`)
  },

  needLogin: () => {
    alert("이 서비스는 로그인 후에 이용하실 수 있습니다.")
  }
};

export default Alerts;
