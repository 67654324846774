// Footer component
import React from "react";
import { FaInstagram } from 'react-icons/fa';
import { AiFillDiscord } from 'react-icons/ai';

function Texts( { children }) {
  return (
    <div className="inline text-[18px] leading-[1.6] text-[#a7a7a7] break-keep font-[300] 
                    max-md:text-[13px] max-md:block">
      {children}
    </div>
  )
}

function Span( { children , value=''} ) {
  return (
    <span className={`leading-[1.6] text-white font-semibold break-keep
                    ${value === 'title'
                    ? 'text-[24px] max-md:text-[15px]'
                    : 'text-[18px] max-md:text-[13px]'}
                    `}>
      {children}
    </span>
  )
}

function FooterContainer( { children } ) {
  return (
    <div className="w-[70%] mx-auto flex justify-between 
                    max-md:w-auto 
                    max-md:h-[192px]
                    max-md:p-[10px]">
      {children}
    </div>
  )
}

function LinkIcon( { children } ) {
  return (
    <div className="flex text-white
                    max-md:ml-auto">
      {children}
    </div>
  )
}

function Br() {
  return (
    <br className='max-md:hidden'>
    </br>
  )
}

function Footer() {
  const currentYear = new Date().getFullYear();

    return (
      <div className="pt-[30px] pb-[20px] box-border bg-black mt-auto
                      max-md:pt-[20px] 
                      max-md:pb-[10px]">
        <FooterContainer>
          <div className="inline 
                          max-md:block">
            <Span value='title'>© {currentYear} IMAGINERY.Inc</Span>
            <Br />
            <Texts>
              <Span>회사명</Span> 주식회사 이매지너리
            </Texts>
            <Br />
            <Texts>
              <Span> 주소</Span> 서울특별시 마포구 월드컵북로 98, 2층
            </Texts>
            <Texts>
              <Span> 대표</Span> 신준호
            </Texts>
            <Texts>
              <Span> 사업자등록번호</Span> 782-81-02642
            </Texts>
            <Br />
            <Texts>
              <Span> 통신판매업</Span> 제2023-서울마포-1651 호
            </Texts>
            <Texts>
              <Span> 대표번호</Span> 0507-1355-9741
            </Texts>
            <Texts>
              <Span> 이메일</Span> business@robobrush.ai
            </Texts>
          </div>
          <LinkIcon className="inline 
                               max-md:block">
            <a href="https://discord.gg/HmWfFD8Vmg" id="foot-discord" target="_blank" rel="noopener noreferrer"
                className="ml-[12px] text-[50px] 
                           max-md:ml-[8px] 
                           max-md:text-[26px]">
              <AiFillDiscord />
            </a>
            <a href="https://www.instagram.com/imaginery_ai/" id="foot-insta" target="_blank" rel="noopener noreferrer"
                className="ml-[12px] text-[50px] 
                           max-md:ml-[8px] 
                           max-md:text-[26px]">
              <FaInstagram />
            </a>
          </LinkIcon>
        </FooterContainer>
      </div>
    )
  }

export default Footer;
