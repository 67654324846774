import Footer from '../components/Footer';

function NotFound (){
    return (
        <div>
            <div style={{
                color: 'white',
                fontSize: '30px',
                fontWeight: 'bold',
                textAlign: 'center',
                paddingTop: '150px', 
                height: '100vh',
                lineHeight: '1.6'
            }}>
                404 Error.<br/>
                Sorry, Page not found.<br/>
                페이지를 찾을 수 없습니다.
            </div>
            <Footer />
        </div>
    );
}

export default NotFound;