import { useCallback, useEffect } from 'react';
import Alerts from '../../Alerts';
import { loadAndDrawImage } from './functions';

// 캔버스에 이미지를 로드
export const useCanvasImageLoader = (canvasRef, backgroundImage, canvasWidth, canvasHeight) => {
  const loadImage = useCallback(() => {
    if (backgroundImage && canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        loadAndDrawImage(context, canvas, img);
      };
      img.onerror = (error) => {
        Alerts.imgLoadFailed(error);
      };
      img.src = `data:image/png;base64,${backgroundImage}`;
    }
  // eslint-disable-next-line
  }, [backgroundImage]);

  useEffect(() => {
    loadImage();
  // eslint-disable-next-line
  }, [loadImage, canvasWidth, canvasHeight]);

  return loadImage;
};

// 이미지 다운로드 함수
export const downloadImage = (canvasRef) => {
  if (canvasRef.current) {
    const canvas = canvasRef.current;
    const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    const link = document.createElement('a');
    link.download = 'image.png';
    link.href = image;
    link.click();
  }
};

// 이미지 클립보드에 복사하는 함수
export const copyImageToClipboard = async (canvasRef) => {
  if (canvasRef.current) {
    try {
      const canvas = canvasRef.current;
      const image = await fetch(canvas.toDataURL());
      const blob = await image.blob();
      await navigator.clipboard.write([
        new ClipboardItem({ 'image/png': blob })
      ]);
      Alerts.copySuccess();
    } catch (error) {
      Alerts.copyFailed(error);
    }
  }
};