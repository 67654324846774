import React from 'react';
import Footer from './Footer';

import { TagProvider } from '../context/TagContext';
import { ImgProvider } from '../context/ImageContext';
import { Wand, WandTags, WandTag, WandInputForm } from './Wand';
import { Container, LandingContainer } from './LandingContainer';

const Landing = () => {

  return (
    <>
      <div className='flex flex-col min-h-screen px-[15%] pt-16
                      max-lg:px-4
                    '>
        <TagProvider>
          <Wand>
            <WandTags>
              <WandTag value="GEN">텍스트로 이미지 생성</WandTag>
              <WandTag value="COM">이미지와 이미지 조합</WandTag>
            </WandTags>
            <ImgProvider>
              <WandInputForm btnId='prompt-gen' />
            </ImgProvider>
          </Wand>
          <Container>
            <LandingContainer />
          </Container>
        </TagProvider>
      </div>

      <Footer />

    </>
  );
};

export default Landing;
