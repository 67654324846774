import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, deleteDoc, doc } from 'firebase/firestore';
import firestore from '../firebaseConfig';
import '../styles/admin.css'; // 기존 스타일 사용

import AdminNavBar from '../components/adminNavBar';
import Alerts from '../Alerts';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';


function AdminPrompt() {
  const navigate = useNavigate();
  const { currentUser, authloading } = useAuth();
  const [loading, setLoading] = useState(true);
  const [scrollData, setScrollData] = useState([]);

  useEffect(() => {
    if (!authloading && !currentUser.isAdmin) {
      Alerts.wrongAccess();
      navigate('/');
    }
  // eslint-disable-next-line
  }, [currentUser, authloading]);

  useEffect(() => {
    const fetchScrollData = async () => {
      const q = query(collection(firestore, "PromptScrolls"));
      const querySnapshot = await getDocs(q);
      const fetchedData = [];
      querySnapshot.forEach((doc) => {
        fetchedData.push({ id: doc.id, ...doc.data() });
      });

      setScrollData(fetchedData);
      setLoading(false);
    };

    fetchScrollData();
  }, []);

  const handleDelete = async (docId) => {
    // 사용자에게 삭제를 확인받습니다.
    const isConfirmed = window.confirm("이 항목을 정말로 삭제하시겠습니까?");
    if (!isConfirmed) {
      return; // 사용자가 취소하면 함수를 종료합니다.
    }
  
    try {
      // Firestore에서 문서를 삭제합니다.
      await deleteDoc(doc(firestore, "PromptScrolls", docId));
      alert("항목이 성공적으로 삭제되었습니다.");
  
      // UI에서 해당 데이터를 제거하기 위해 상태를 업데이트합니다.
      setScrollData(currentData => currentData.filter(item => item.id !== docId));
    } catch (error) {
      console.error("항목 삭제 중 오류 발생: ", error);
      alert("항목을 삭제하는 동안 오류가 발생했습니다.");
    }
  };
  

  return (
    <>
      <AdminNavBar />
      <div className='prompt'>
        <h5>총 개수: {scrollData.length} / 20</h5>
        {loading ? (
          <p>데이터를 불러오는 중...</p>
        ) : (
          <div className='list'>
            {scrollData.map((item) => (
              <div className='data' key={item.id}>
                <img src={item.imageData} alt="Rating Preview" />
                <div className='data-info'>
                  <div>
                    <p>Rating: {item.rating}</p>
                    <p>Prompt: {item.prompt}</p>
                    <button onClick={() => handleDelete(item.id)}>삭제</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default AdminPrompt;
