import React, { useEffect, useState } from 'react';
import { Stage, Layer, Image as KonvaImage, Transformer, Line, Rect } from 'react-konva';
import { Undo2, Redo2 } from 'lucide-react';
import '../styles/KonvaCanvas.css'
import ToastBar from './ToastBar';
import { makeNewImage } from './modules/functions';

const EditKonvaCanvas = ({ setImages, images, setUndoStack, undoStack, setRedoStack, redoStack,
  setSelectedId, selectedId, transformerRef, stageRef, canvasWidth, canvasHeight, undo, redo, lineUndo, lineRedo,
  activeTab, brushSize, lines, setLines, setHistory, history, setHistoryStep, historyStep }) => {

  const [toastMessage, setToastMessage] = useState('');

  // redo undo 히스토리
  const updateImages = (newImages) => {
    setUndoStack([...undoStack, images]);
    setImages(newImages);
    setRedoStack([]);
  };

  // 캔버스 내용물 조작시 상태 저장
  const handleTransformChange = (newAttrs, id) => {
    const newImages = images.map((img) => {
      if (img.id === id) {
        return { ...img, ...newAttrs };
      }
      return img;
    });
    updateImages(newImages);
  };

  // 이미지 클릭하면 해당 이미지 선택
  const handleImageClick = (id) => {
    setSelectedId(id);
  };

  // 선택 해제
  const clearSelection = (e) => {
    // 이미지 레이어나 트랜스포머가 아닌 다른 곳을 클릭했을 때
    if (e.target === e.target.getStage()) {
      setSelectedId(null);
      transformerRef.current.nodes([]);
      transformerRef.current.getLayer().batchDraw();
    }
  };

  const handleTransformEnd = (e, imgId) => {
    const node = e.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();
    
    const newX = node.x();
    const newY = node.y();
    const newWidth = node.width() * scaleX;
    const newHeight = node.height() * scaleY;
    const newRotation = node.rotation();  // 회전 상태 저장
  
    node.scaleX(1);
    node.scaleY(1);
  
    handleTransformChange({
      x: newX,
      y: newY,
      width: newWidth,
      height: newHeight,
      rotation: newRotation,  // 회전 상태 추가
    }, imgId);
  };

  const handleDragEnd = (e) => {
    const node = e.target;
    const newX = node.x();
    const newY = node.y();
    const newWidth = node.width() * node.scaleX();
    const newHeight = node.height() * node.scaleY();

    // 스테이지의 경계를 계산
    const stageWidth = stageRef.current.width();
    const stageHeight = stageRef.current.height();

    // 이미지가 스테이지 바깥으로 나가는 경우
    let outOfBounds = false;

    if (newX + newWidth < 0 || newX > stageWidth || newY + newHeight < 0 || newY > stageHeight) {
      outOfBounds = true;
    }

    // 스테이지 밖으로 나간 경우, 레이어를 캔버스 가운데로 이동
    if (outOfBounds) {
      const centerX = (stageWidth - newWidth) / 2;
      const centerY = (stageHeight - newHeight) / 2;
      node.position({ x: centerX, y: centerY });
      node.getLayer().batchDraw(); // 캔버스 업데이트

      // 토스트 메시지 표시
      setToastMessage('레이어가 캔버스 밖으로 빠져나가서 가운데 위치로 되돌렸습니다');
    } else {
      // 정상적인 경우, 위치 및 상태 업데이트
      const newImages = images.map(img => {
        if (img.id === node.id()) {
          return { 
            ...img, 
            x: newX, 
            y: newY,
            rotation: node.rotation()  // 회전 상태 저장
          };
        }
        return img;
      });

      setImages(newImages);
    }
  };

  useEffect(() => {
    // activeTab이 변경될 때만 선택된 레이어 해제
    setSelectedId(null);
    if (transformerRef.current) {
        transformerRef.current.nodes([]);
    }
  // eslint-disable-next-line
  }, [activeTab]);

  // 드래그 드랍으로 업로드
  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const reader = new FileReader();
  
    reader.onload = () => {
      const img = new window.Image();
      img.src = reader.result;
      img.onload = () => {
        const newImageId = `upimg-${Date.now()}`;
        const newImage = makeNewImage({
          canvasWidth: canvasWidth, 
          canvasHeight: canvasHeight,
          image: img, 
          id: newImageId
        });
  
        setUndoStack([...undoStack, images]);
        setImages(prev => [...prev, newImage]);
        setSelectedId(newImageId);
        setRedoStack([]);
      };
    };
  
    if (files.length > 0) {
      reader.readAsDataURL(files[0]);
    }
  };
  

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const stage = stageRef.current;
    stage.container().addEventListener('dragover', handleDragOver);
    stage.container().addEventListener('drop', handleDrop);

    return () => {
      stage.container().removeEventListener('dragover', handleDragOver);
      stage.container().removeEventListener('drop', handleDrop);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedId && transformerRef.current) {
      const node = stageRef.current.findOne(`#${selectedId}`);
      if (node) {
        transformerRef.current.nodes([node]);
        transformerRef.current.getLayer().batchDraw();
      } else {
        transformerRef.current.nodes([]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, images]);

  


  // 브러싱 Brushing 관련
  const isDrawing = React.useRef(false);

  // 브러시 관련 핸들러에 터치 대응 추가
  const handleMouseDown = (e) => {
    let pos;
    if (e.evt.touches && e.evt.touches.length > 0) {
      const stage = e.target.getStage();
      // const touch = e.evt.touches[0];
      pos = stage.getPointerPosition(); // 스테이지 좌표로 변환
    } else {
      pos = e.target.getStage().getPointerPosition();
    }
    isDrawing.current = true;
    setLines([...lines, { points: [pos.x, pos.y], brushSize: brushSize }]);
  };
  
  const handleMouseMove = (e) => {
    if (!isDrawing.current) {
      return;
    }
    const stage = e.target.getStage();
    let point;    
    if (e.evt.touches && e.evt.touches.length > 0) {
      // const touch = e.evt.touches[0];
      point = stage.getPointerPosition(); // 터치 좌표 변환
    } else {
      point = stage.getPointerPosition();
    }
    let lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points.concat([point.x, point.y]);    
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = (e) => {
    if (isDrawing.current) {
      addToHistory();
      isDrawing.current = false;
    }
  };

  // 브러시 레이어 삭제
  const deleteBrushLayer = () => {
    const stage = stageRef.current;
    const brushLayer = stage.findOne('.brush');

    setHistory([{ lines: [] }]);
    setHistoryStep(0);

    setLines([]);
    brushLayer.batchDraw();
  };

  useEffect(() => {
    const stage = stageRef.current;
    stage.container().addEventListener('dragover', handleDragOver);
    stage.container().addEventListener('drop', handleDrop);

    return () => {
      stage.container().removeEventListener('dragover', handleDragOver);
      stage.container().removeEventListener('drop', handleDrop);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedId && transformerRef.current) {
      const node = stageRef.current.findOne(`#${selectedId}`);
      if (node) {
        transformerRef.current.nodes([node]);
        transformerRef.current.getLayer().batchDraw();
      } else {
        transformerRef.current.nodes([]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, images]);

  useEffect(() => {
    if (stageRef.current) {
      const stage = stageRef.current;
      //이름이 brush인 레이어
      const brushLayer = stage.findOne('.brush');
  
      // 모든 레이어
      const allLayers = stage.find('Layer');
            
      if (brushLayer) {
        const konvaContainer = brushLayer.getCanvas()._canvas; // HTMLCanvasElement
  
        // z-index 설정
        konvaContainer.style.zIndex = (activeTab === 'brushing') ? '9999' : '-1';
      }
  
      allLayers.forEach(layer => {
        if (layer !== brushLayer) {
          layer.listening(!(activeTab === 'brushing'));
        }
      });
  
      if (activeTab === 'brushing') {
        // 브러싱 모드일 때 마우스 및 터치 이벤트 리스너 추가
        stage.on('mousedown', handleMouseDown);
        stage.on('mousemove', handleMouseMove);
        stage.on('mouseup', handleMouseUp);
        stage.on('touchstart', handleMouseDown);
        stage.on('touchmove', handleMouseMove);
        stage.on('touchend', handleMouseUp);
      } else {
        // 브러싱 모드가 아닐 때 마우스 및 터치 이벤트 리스너 제거
        stage.off('mousedown');
        stage.off('mousemove');
        stage.off('mouseup');
        stage.off('touchstart');
        stage.off('touchmove');
        stage.off('touchend');
  
        deleteBrushLayer();
      }
  
      // 클린업 함수
      return () => {
        stage.off('mousedown');
        stage.off('mousemove');
        stage.off('mouseup');
        stage.off('touchstart');
        stage.off('touchmove');
        stage.off('touchend');
  
        allLayers.forEach(layer => layer.listening(true));
      };
    }
  // eslint-disable-next-line 
  }, [activeTab]);  

  const addToHistory = () => {
    const newHistory = history.slice(0, historyStep + 1);
    newHistory.push({ lines });
    setHistory(newHistory);
    setHistoryStep(newHistory.length - 1);
  };

  return (
    <div className="EditKonvaCanvas relative"
      onMouseEnter={(e) => {
        if (activeTab === 'brushing') {
          e.currentTarget.classList.add('custom-cursor');
        }
      }}
      onMouseLeave={(e) => {
        if (activeTab === 'brushing') {
          e.currentTarget.classList.remove('custom-cursor');
        }
      }}
    >
      <ToastBar message={toastMessage} duration={3000} setToastMessage={setToastMessage} />
      <Stage
        width={canvasWidth}
        height={canvasHeight}
        ref={stageRef}
        onClick={clearSelection}
        className='EditKonvaStage'

        onMouseDown= {(activeTab === 'brushing') ? handleMouseDown : undefined}
        onMousemove={(activeTab === 'brushing') ? handleMouseMove : undefined}
        onMouseup={(activeTab === 'brushing') ? handleMouseUp : undefined}
        onTouchStart={(activeTab === 'brushing') ? handleMouseDown : undefined}
        onTouchMove={(activeTab === 'brushing') ? handleMouseMove : undefined}
        onTouchEnd={(activeTab === 'brushing') ? handleMouseUp : undefined}
      >
        <Layer name='images'>
          {images.map((img, index) => (
            <KonvaImage
              key={img.id} // id를 key로 사용
              image={img.image}
              x={img.x}
              y={img.y}
              width={img.width}
              height={img.height}
              rotation={img.rotation || 0}
              id={img.id}
              draggable={activeTab === 'layer' && selectedId === img.id}
              onClick={activeTab === 'layer' ? () => handleImageClick(img.id) : undefined}
              onTap={activeTab === 'layer' ? () => handleImageClick(img.id) : undefined}
              onTransformEnd={(e) => handleTransformEnd(e, img.id)}
              onDragStart={(e) => {
                setUndoStack([...undoStack, images]);
                setRedoStack([]);
              }}
              onDragEnd={handleDragEnd}
            />
          ))}
          <Transformer ref={transformerRef} />
        </Layer>
        <Layer 
          name='bg'
          visible={0}
          >
          <Rect
            x={0}
            y={0}
            width={999}
            height={999}
            fill="black"
          />
        </Layer>
        <Layer 
          name='brush'
          opacity={0.5}
          >
          {lines.map((line, i) => (
            <Line
              key={i}
              points={line.points}
              stroke="#a090ff"
              strokeWidth={line.brushSize || brushSize}  // 각 선의 brushSize 또는 현재 brushSize 사용
              tension={0.5}
              lineCap="round"
              lineJoin="round"
              globalCompositeOperation={'source-over'}
            />
          ))}
        </Layer>
      </Stage>

      <div className="absolute right-[-40px] top-0 flex flex-col space-y-2 gap-[10px]">
        <button className="rounded-full p-1 shadow-md border-[1px] border-white"
          onClick={activeTab === 'layer' ? undo : lineUndo}
          disabled={activeTab === 'layer' ? undoStack.length === 0 : historyStep <= 0}>
          <Undo2 className="text-white" size={16} />
        </button>
        <button className="rounded-full p-1 shadow-md border-[1px] border-white"
          onClick={activeTab === 'layer' ? redo : lineRedo}
          disabled={activeTab === 'layer' ? redoStack.length === 0 : historyStep >= history.length - 1}>
          <Redo2 className="text-white" size={16} />
        </button>
      </div>

    </div>
  );
};

export default EditKonvaCanvas;