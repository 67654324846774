import React, { useState, useEffect } from 'react';

const ToastBar = ({ message, duration, setToastMessage }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
        setToastMessage('');
      }, duration);

      return () => clearTimeout(timer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, duration]);

  return (
    visible && (
      <div className="fixed top-[180px] left-1/2 transform -translate-x-1/2 bg-[#9E0D0D] bg-opacity-70 text-white py-2 px-4 rounded z-50">
        {message}
      </div>
    )
  );
};

export default ToastBar;
