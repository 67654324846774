import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GradientOutlineButton, OutlineButton } from '../components/Buttons';
import { handleAxios } from '../components/modules/AxiosModule';
import config from '../config';
import LoadingModal from './LoadingModal';
import { FaCoins } from 'react-icons/fa';
import Alerts from '../Alerts';
import { copyImageToClipboard, downloadImage, useCanvasImageLoader } from './modules/canvasFuntions';

const ResultCombine = ({ data, base64Images, currentUser, loginCheck, checkCredits, fetchCredits, isLoading, setIsLoading, canvasWidth,
  setCanvasWidth, canvasHeight, setCanvasHeight }) => {

  const navigate = useNavigate()
  const [backgroundImage, setBackgroundImage] = useState(data.b64image)
  const canvasRef = useRef(null);
  const apiRefURL = config.REF_GEN_APIURL;

  //캔버스에 로드
  useCanvasImageLoader(canvasRef, backgroundImage, canvasHeight, canvasWidth)

  // 재생성 요청
  const handleRegenerateCombine =  async () => {
    loginCheck()
    if (!loginCheck()) return;
    const creditsAvailable = checkCredits(1);
    if (!creditsAvailable) return;
    setIsLoading(true);
    try {
      const jsonData = JSON.stringify({ data: base64Images, user_sub: currentUser.sub });

      const response = await handleAxios("post", apiRefURL, jsonData);

      if (response.status === 200) {
        await fetchCredits();
        setBackgroundImage(response.data.b64image)
        navigate('/mycanvas-result', { state: { data: response.data, base64Images } });
      } else if (response.status === 400 && response.data.detail === "Not enough credits") {
        Alerts.noCredits()
        navigate('/pricing');
      } else if (response.data && response.data.message) {
        alert(response.data.message);
      }
    } catch (error) {
      Alerts.generateFailed(error)
    } finally {
      setIsLoading(false);
    }
  };

  //편집 페이지로
  const handleEditStart = () => {
    // 모바일 경고 함수
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      Alerts.onlyDesktop()
    }
    navigate('/mycanvas-edit', {
      state: { image: { b64image: backgroundImage, url: data.url } },
    });
  };

  return (
    <div className="flex flex-col items-center justify-start px-4 mt-20">
      {isLoading && <LoadingModal />}
      <div className="relative w-full max-w-[660px] h-auto aspect-square bg-gray-200 mb-4">
        <canvas className="w-full h-full object-contain"
          ref={canvasRef} 
          width={canvasWidth} 
          height={canvasHeight} 
        ></canvas>
      </div>
      {!isLoading && (
        <div className="grid grid-cols-2 gap-4 sm:w-[auto] w-[90%]">
          <GradientOutlineButton text="편집 시작하기" handleClick={handleEditStart} />
          <GradientOutlineButton text="다시 생성하기" leftIcon={<FaCoins className="text-[16px] sm:text-[24px] align-middle" />} handleClick={() => handleRegenerateCombine(base64Images)} />
          <OutlineButton text="복사하기" handleClick={() => copyImageToClipboard(canvasRef)} />
          <OutlineButton text="다운로드" handleClick={() => downloadImage(canvasRef)} />
        </div>
      )}
    </div>
  );
};

export default ResultCombine;