import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useCredit } from '../context/CreditContext';
import SideController from '../components/SideController';
import EditKonvaCanvas from '../components/EditKonvaCanvas';
import config from '../config';
import LoadingModal from '../components/LoadingModal';
import { handleAxios } from '../components/modules/AxiosModule';
import { makeNewImage, stripBase64Prefix, cropImage } from '../components/modules/functions';
import Alerts from '../Alerts';

const Edit = () => {
  const location = useLocation();
  const { fetchCredits, checkCredits } = useCredit();
  const { currentUser, loginCheck, authloading } = useAuth();

  const [activeTab, setActiveTab] = useState('layer');

  const [images, setImages] = useState([]);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const transformerRef = useRef(null);
  const stageRef = useRef(null);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [removingBg, setRemovingBg] = useState(false)

  const [brushSize, setBrushSize] = useState(15);
  const [lines, setLines] = useState([]);
  const [history, setHistory] = useState([{ lines: [] }]);
  const [historyStep, setHistoryStep] = useState(0);

  const [canvasWidth, setCanvasWidth] = useState(660);
  const [canvasHeight, setCanvasHeight] = useState(660);
  
  const [isMobile, setIsMobile] = useState(false);

  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const initialHeight = window.innerHeight;

  // 가상 키보드 감지 함수
  useEffect(() => {
    const handleViewportChange = () => {
      if (window.visualViewport.height < initialHeight * 0.75) {
        setIsKeyboardVisible(true);
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }, 100);
      } else {
        setIsKeyboardVisible(false);
      }
    };
    window.visualViewport.addEventListener('resize', handleViewportChange);
    return () => {
      window.visualViewport.removeEventListener('resize', handleViewportChange);
    };
  // eslint-disable-next-line
  }, []);

  const updateCanvasSize = useCallback(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobileRegex = /android|iPad|iPhone|iPod/i;

    if (mobileRegex.test(userAgent.toLowerCase()) || window.innerWidth <= 768) {
      setIsMobile(true);
      const newWidth = window.innerWidth * 0.7; // 70% of viewport width
      setCanvasWidth(newWidth);
      setCanvasHeight(newWidth); // Make canvas square
    } else {
      setIsMobile(false);
      setCanvasWidth(660);
      setCanvasHeight(660);
    }
  }, []);

  useEffect(() => {
    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, [updateCanvasSize]);

  useEffect(() => {
    loginCheck('/');
  // eslint-disable-next-line
  }, [currentUser, authloading ]);

  useEffect(() => {
    const initializeImage = () => {
      const state = location.state;
      if (state) {
        let imgSrc;
        if (state.image.b64image) {
          imgSrc = `data:image/png;base64,${state.image.b64image}`; // Base64 기반 이미지
        }
  
        if (imgSrc) {
          const img = new window.Image();
          img.src = imgSrc;
          img.onload = () => {
            const newImage = makeNewImage({
              canvasWidth: canvasWidth,
              canvasHeight: canvasHeight,
              url: state.image.url,
              image: img,
              id: 'initial-image'
            })
            setImages([ newImage ]);
            setSelectedId('initial-image');
          };
        }
      }
    };
  
    initializeImage();
  }, [location.state, canvasHeight, canvasWidth]);

  // 레이어 모드
  const undo = () => {
    if (undoStack.length > 0) {
      const newUndoStack = [...undoStack];
      const previousState = newUndoStack.pop();
      setRedoStack([...redoStack, images]);
      setImages(previousState);
      setUndoStack(newUndoStack);
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const newRedoStack = [...redoStack];
      const nextState = newRedoStack.pop();
      setUndoStack([...undoStack, images]);
      setImages(nextState);
      setRedoStack(newRedoStack);
    }
  };

  const deleteLayer = () => {
    setUndoStack([...undoStack, images]);
    setRedoStack([]);

    setImages(prev => prev.filter(img => img.id !== selectedId));
    setSelectedId(null);
    transformerRef.current.nodes([]);
    transformerRef.current.getLayer().batchDraw();
  };

  // 레이어 z-index 이동
  const moveLayerForward = () => {
    setUndoStack([...undoStack, images]);
    const index = images.findIndex(img => img.id === selectedId);
    if (index < images.length - 1) {
      const newImages = [...images];
      [newImages[index], newImages[index + 1]] = [newImages[index + 1], newImages[index]];
      setImages(newImages);
      setRedoStack([]);
    }
  };

  const moveLayerBackward = () => {
    setUndoStack([...undoStack, images]);
    const index = images.findIndex(img => img.id === selectedId);
    if (index > 0) {
      const newImages = [...images];
      [newImages[index], newImages[index - 1]] = [newImages[index - 1], newImages[index]];
      setImages(newImages);
      setRedoStack([]);
    }
  };

  // 업로드
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new window.Image();
        img.src = e.target.result;
        img.onload = () => {
          const newImageId = `upimg-${Date.now()}`;
          const newImage = makeNewImage({
            canvasWidth: canvasWidth,
            canvasHeight: canvasHeight,
            image: img,
            id: newImageId
          });
          setUndoStack([...undoStack, images]);
          setImages(prev => [...prev, newImage]);
          setSelectedId(newImageId);
          setRedoStack([]);
          setIsLoading(false);
        };
      };
      reader.readAsDataURL(file);
      event.target.value = '';
    }
  };

  // 배경 제거
  const handleBackgroundRemoveClick = async () => {
    if (!selectedId) {
      Alerts.needImgSelect();
      return;
    }

    const selectedImage = images.find(img => img.id === selectedId);
    const stage = stageRef.current;
    if (!stage) {
      Alerts.noStage();
      return;
    }

    setUndoStack([...undoStack, images]);
    setRedoStack([]);
    setRemovingBg(true);

    try {
      const imageNode = stage.findOne(`#${selectedId}`);
      const imageDataUrl = imageNode.toDataURL({ pixelRatio: 3 });
      const apiRefURL = config.REMOVE_BG_URL;
      const strippedImageData = stripBase64Prefix(imageDataUrl);
      const requestData = {
        image: strippedImageData
      };

      const response = await handleAxios("post", apiRefURL, requestData);

      const img = new window.Image();
      img.src = `data:image/png;base64,${response.data}`;
      img.onload = () => {
        cropImage(img, (croppedBlob) => {
          const objectUrl = URL.createObjectURL(croppedBlob);
          const croppedImage = new window.Image();
          croppedImage.src = objectUrl;
          croppedImage.onload = () => {
            const newImageId = `${selectedImage.id}-${new Date().getTime()}`;
            const newImage = makeNewImage({
              selectedImage: selectedImage,
              croppedImage: croppedImage,
              url: selectedImage.url,
              image: img,
              id: newImageId
            });
            const filteredImages = images.filter(img => img.id !== selectedId);
            setImages([...filteredImages, newImage]);
            setSelectedId(newImage.id);
          };
        });
      };
    } catch (error) {
      Alerts.bgRemoveError(error)
    } finally {
      setRemovingBg(false);
    }
  };

  // 텍스트로 생성
  const handleTextToGenerate = async (prompt) => {
    const apiURL = config.PROMPT_GEN_APIURL;
    loginCheck()
    if (!loginCheck()) return;
    checkCredits(1);
    setIsLoading(true)
    const requestData = {
      user_sub: currentUser.sub,
      prompt: prompt,
    };
    try {
      const response = await handleAxios("post", apiURL, requestData);
  
      if (response.data) {
        fetchCredits()
        const imgSrc = `data:image/png;base64,${response.data.b64image}`;
  
        const img = new window.Image();
        img.src = imgSrc;
        img.onload = () => {
          const newImageId = `genimg-${Date.now()}`;
          const newImage = makeNewImage({
            canvasWidth: canvasWidth,
            canvasHeight: canvasHeight,
            url: response.data.url,
            image: img,
            id: newImageId
          });
          // const scaleX = canvasWidth / img.width;
          // const scaleY = canvasHeight / img.height;
          // const scale = Math.min(scaleX, scaleY);
          // const newImageId = `genimg-${Date.now()}`;
          // const newImage = {
          //   image: img,
          //   url: response.data.url,
          //   x: (canvasWidth - img.width * scale) / 2,
          //   y: (canvasHeight - img.height * scale) / 2,
          //   width: img.width * scale,
          //   height: img.height * scale,
          //   id: newImageId,
          // };
  
          // 이미지를 레이어에 추가
          setUndoStack([...undoStack, images]);
          setImages(prev => [...prev, newImage]);
          setSelectedId(newImageId);
          setRedoStack([]);
        };
      } else if (response.data && response.data.message) {
        alert(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.detail === "Not enough credits") {
          Alerts.noCredits(error);
        } else {
          Alerts.invalidRequest(error)
        }
      } else {
        Alerts.generateFailed(error)
      }
    } finally {
      setIsLoading(false)
    }
  };
  

  // 브러싱 모드
  // 히스토리  
  const lineUndo = useCallback(() => {
      if (historyStep > 0) {
      setHistoryStep(historyStep - 1);
      const previousState = history[historyStep - 1];
      setLines(previousState.lines);
    }
  }, [history, historyStep, setLines]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lineRedo = useCallback(() => {
    if (historyStep < history.length - 1) {
      setHistoryStep(historyStep + 1);
      const nextState = history[historyStep + 1];
      setLines(nextState.lines);
    }});


  // 내보내기 모드
  const copyToClipboard = async () => {
    try {
      const stage = stageRef.current;
      const dataURL = stage.toDataURL({ pixelRatio: 3 });
      const blob = await (await fetch(dataURL)).blob();
      
      // 클립보드에 복사
      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob
        })
      ]);
      Alerts.copySuccess()
    } catch (error) {
      Alerts.copyFailed(error)
    }
  };
  
  const downloadCanvasAsImage = () => {
    try {
      const stage = stageRef.current;
      const dataURL = stage.toDataURL({ pixelRatio: 3 });
      
      // 다운로드 링크 생성
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'image.png';
      
      // 링크 클릭하여 다운로드 트리거
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      Alerts.downloadError(error);
    }
  };


  // 브러싱 영역 관련
  const maskToBase64 = () => {
    const stage = stageRef.current.getStage();
    
    // 1. 모든 레이어 숨기기
    stage.getLayers().forEach(function(layer) {
      layer.hide();
    });
  
    // 2. 특정 레이어만 표시
    const bgLayer = stage.findOne('.bg');
    bgLayer.show();
    const brushLayer = stage.findOne('.brush');
    brushLayer.show();
    brushLayer.opacity(1.0);
    // 하위 라인 색상 #ffffff로 변경
    brushLayer.getChildren().forEach(function(i) {
      i.stroke('#ffffff');
    });
    // dataUrl로 변환
    const imageDataUrl = stage.toDataURL({
      mimeType: 'image/png',
      quality: 1,
      pixelRatio: 512.0/stage.width(),
    });
    bgLayer.hide();
  
    // 3. 상태를 업데이트
    // setMasked(imageDataUrl);
  
    // 4. 모든 레이어 다시 표시
    stage.getLayers().forEach(function(layer) {
      layer.show();
    });
    brushLayer.opacity(0.5);
    // 하위 라인 색상 #a090ff 변경
    brushLayer.getChildren().forEach(function(i) {
      i.stroke('#a090ff');
    });

    return imageDataUrl;
  };

  const handleEditMask = async (prompt) => {
    loginCheck()
    if (!loginCheck()) return;
    const creditsAvailable = checkCredits(1);
    if (!creditsAvailable) return;
    setIsLoading(true)
    try {
      const storedToken = JSON.parse(localStorage.getItem('token'));
      const maskedImageDataUrl = stripBase64Prefix(maskToBase64());

      const generates_images = images.filter(item => item.url !== '').map(item => item.url);

      const stage = stageRef.current.getStage();
      ///레이어 전부 hide
      stage.getLayers().forEach(function(layer) {
        layer.hide();
      });

      const imageLayer = stage.findOne('.images')
      imageLayer.show();
      const imageChild = imageLayer.getChildren();
      ///이미지 전부 hide
      imageChild.forEach(item => {
        item.hide();
      });
      const upload_images = [];
      
      ///id가 upimg로 시작하는 원소만 show 후 dataurl로 변환/저장
      imageChild.forEach(item => {
        if (item.attrs.id && item.attrs.id.split('-')[0] === 'upimg') {
          item.show();
          const imageDataUrl = stage.toDataURL({
            mimeType: 'image/png',
            quality: 1,
            pixelRatio: 512.0/stage.width(),
          });
          upload_images.push(stripBase64Prefix(imageDataUrl));
          item.hide();
        }
      });
      ///이미지 전부 show
      imageChild.forEach(item => {
        item.show();
      });
    
      // images.filter(item => item.url === '').map(item => item.image);
      const image = stage.toDataURL({
        mimeType: 'image/png',
        quality: 1,
        pixelRatio: 512.0/stage.width(),
      });
      
      const brushLayer = stage.findOne('.brush');
      brushLayer.show();

      if (storedToken && storedToken.token) {
        
        // 영역 편집 요청
        const apiURL = config.MASKED_GEN_URL;
        const requestData = {
          upload_images: upload_images,
          generates_images: generates_images,
          prompt: prompt,
          image: stripBase64Prefix(image),
          mask: maskedImageDataUrl,
        };
        const response = await handleAxios("post", apiURL, requestData);
    
        if (response.data) {
          fetchCredits()
          const imgSrc = `data:image/png;base64,${response.data.b64image.replaceAll('"','')}`;
    
          const img = new window.Image();
          img.src = imgSrc;
          img.onload = () => {
            const newImageId = `image-${Date.now()}`;
            const newImage = makeNewImage({
              canvasWidth: canvasWidth,
              canvasHeight: canvasHeight,
              url: response.data.url,
              image: img,
              id: newImageId
            });
            // const scaleX = canvasWidth / img.width;
            // const scaleY = canvasHeight / img.height;
            // const scale = Math.min(scaleX, scaleY);
            // const newImageId = `image-${Date.now()}`;
            // const newImage = {
            //   image: img,
            //   url: response.data.url,
            //   x: (canvasWidth - img.width * scale) / 2,
            //   y: (canvasHeight - img.height * scale) / 2,
            //   width: img.width * scale,
            //   height: img.height * scale,
            //   id: newImageId,
            // };
    
            // 이미지를 레이어에 추가
            setUndoStack([...undoStack, images]);
            setRedoStack([]);
            setImages([newImage]);
            setLines([]);
            setHistory([{ lines: [] }]);
            setHistoryStep(0);
          };
        } else if (response.data && response.data.message) {
          alert(response.data.message);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.detail === "Not enough credits") {
          Alerts.noCredits(error);
        } else {
          Alerts.invalidRequest(error);
        }
      } else {
        Alerts.generateFailed(error)
      }
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <div className='flex flex-col sm:flex-row sm:h-screen h-[100%] overflow-hidden'>
      {isLoading && <LoadingModal />}
      {removingBg && <LoadingModal />}

      <div className={`flex sm:order-2 order-1 sm:pt-0 sm:ml-[600px] justify-center items-center
                      ${isKeyboardVisible ? 'pt-[110px]' : 'pt-[70px]'}`}>
        <EditKonvaCanvas
          setImages={setImages}
          images={images}
          setUndoStack={setUndoStack}
          undoStack={undoStack}
          setRedoStack={setRedoStack}
          redoStack={redoStack}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
          transformerRef={transformerRef}
          stageRef={stageRef}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          activeTab={activeTab}
          brushSize={brushSize}
          lines={lines}
          setLines={setLines}
          history={history}
          setHistory={setHistory}
          historyStep={historyStep}
          setHistoryStep={setHistoryStep}
          undo={undo}
          redo={redo}
          lineUndo={lineUndo}
          lineRedo={lineRedo}
        />
      </div>

      <div className='sm:order-1 order-2'>
        <SideController
          isKeyboardVisible={isKeyboardVisible}
          handleEditMask={handleEditMask}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          undo={undo}
          redo={redo}
          undoStack={undoStack}
          redoStack={redoStack}
          deleteLayer={deleteLayer}
          moveLayerForward={moveLayerForward}
          moveLayerBackward={moveLayerBackward}
          handleImageUpload={handleImageUpload}
          handleTextToGenerate={handleTextToGenerate}
          handleBackgroundRemoveClick={handleBackgroundRemoveClick}
          selectedId={selectedId}
          fileInputRef={fileInputRef}
          removingBg={removingBg}
          brushSize={brushSize}
          setBrushSize={setBrushSize}
          lines={lines}
          lineUndo={lineUndo}
          lineRedo={lineRedo}
          history={history}
          historyStep={historyStep}
          downloadCanvasAsImage={downloadCanvasAsImage}
          copyToClipboard={copyToClipboard}
          canvasHeight={canvasHeight}
          isMobile={isMobile}
        />
      </div>
      
    </div>
  );
};

export default Edit;
