import { Helmet } from 'react-helmet-async';
import Landing from '../components/Landing'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const Main = () => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
  
    useEffect(() => {
      if (currentUser) {
        navigate('/mycanvas-home');
      }
    }, [currentUser, navigate]);
    
    return (
        <>
            <Helmet>
                <title>불가능한 이미지는 없습니다. 로보브러시</title>
            </Helmet>
            <Landing />
        </>
        );
    }

export default Main;