// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"; // Storage 사용을 위해 추가
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// const firebaseConfig = {
//   apiKey: "AIzaSyDqRxA0Rvlri_sB2T6mjFY_r2U1WyHnkm0",
//   authDomain: "robobrush-test.firebaseapp.com",
//   projectId: "robobrush-test",
//   storageBucket: "robobrush-test.appspot.com",
//   messagingSenderId: "374517628310",
//   appId: "1:374517628310:web:b6b8f44acc9751a9b471b7"
// };

// 본 결제 서버
const firebaseConfig = {
  apiKey: "AIzaSyA5U6yGx4N2Q82DNIDJnGFrigGAa_1hkQQ",
  authDomain: "robobrush-origin.firebaseapp.com",
  projectId: "robobrush-origin",
  storageBucket: "robobrush-origin.appspot.com",
  messagingSenderId: "862030905871",
  appId: "1:862030905871:web:4a32fce558c16473dc997a",
  measurementId: "G-6JW3XYF2KJ"
};

// 백업&테스트서버
// const firebaseConfig = {
//   apiKey: "AIzaSyBY4w5n5vpDZbyZKlDBZdwhvZ6z5tILXbo",
//   authDomain: "robobrush-backup.firebaseapp.com",
//   projectId: "robobrush-backup",
//   storageBucket: "robobrush-backup.appspot.com",
//   messagingSenderId: "562058046312",
//   appId: "1:562058046312:web:b77cf030f0c2bca89fc4dd",
//   measurementId: "G-3SFH36E9F9"
// };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

// Firebase 인증
const auth = getAuth(app);

export { auth };

const storage = getStorage(app); // Firebase Storage 초기화

export { storage }

export default firestore;