import React, { useEffect } from 'react';
import { useAuth } from '../context/AuthContext';

import { TagProvider } from '../context/TagContext';
import { ImgProvider } from '../context/ImageContext';
import { Wand, WandTags, WandTag, WandInputForm } from '../components/Wand';
import { Container } from '../components/LandingContainer';
import ImageHistory from '../components/ImageHistory';

const MyCanvasHome = () => {
  const { currentUser, loginCheck, authloading } = useAuth();

  useEffect(() => {
    loginCheck('/')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, authloading]);

  return (
    <>
      <div className='flex flex-col min-h-screen px-[15%] pt-16
                      max-lg:px-4'>
        <TagProvider>
          <Wand>
            <WandTags>
              <WandTag value="GEN">텍스트로 이미지 생성</WandTag>
              <WandTag value="COM">이미지와 이미지 조합</WandTag>
            </WandTags>
            <ImgProvider>
              <WandInputForm btnId='prompt-gen' />
            </ImgProvider>
          </Wand>
          <Container>
            <ImageHistory currentUser={currentUser} />
          </Container>
        </TagProvider>
      </div>

    </>
  );
};

export default MyCanvasHome;
