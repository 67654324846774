import React from 'react';
import { useTag } from '../context/TagContext';
import '../styles/RefGenAnimation.css';

import wine from '../assets/images/wine.png';
import hap1 from '../assets/images/hap1.jpeg';
import hap2 from '../assets/images/hap2.jpeg';
import hap3 from '../assets/images/hap3.png';

const Container = ({ children }) => {
  return (
      <div className='w-full mt-6 mb-12 mx-auto'>
        {children}
      </div>
  )
}

const LandingContainer = () => {
  const { currentTag } = useTag();

  return (
      <div className='flex justify-center gap-[4%] overflow-visible
                    '>
        {currentTag === 'GEN' && 
          <>
            <div className='flex flex-col flex-1 w-[512px] items-end justify-center'>
              <img src={wine} alt="wine" className="" />
            </div>
            <div className='flex flex-col flex-1 items-start justify-center text-left
                            max-md:text-center max-md:items-center max-md:my-4'>
              <h2 className='text-white text-4xl font-bold leadng-6 break-keep
                             max-md:text-3xl
                            '>
                <span className="text-[#F8A80E]">키워드</span>만 입력하시면<br/>
                AI가 이미지를 만들어 드려요!
              </h2>
            </div>
          </>
        }
        {currentTag === 'COM' && 
          <>
            <div className='flex flex-col flex-1 items-end justify-center text-right
                            max-md:text-center max-md:items-center max-md:my-4'>
              <h2 className='text-white text-4xl font-bold leadng-6 break-keep
                             max-md:text-3xl
                            '>
                다양한 <span className="text-[#F8A80E]">레퍼런스</span>를 조합해<br/>
                <span className="text-[#F8A80E]">독창적인 디자인</span>을 만들어 봐요!
              </h2>
            </div>
            <div className='flex flex-col flex-1 relative w-[512px] items-start justify-center'>
              <div className={`flex ${currentTag === 'COM' ? 'animate' : ''}`}>
                <img src={hap1} alt="1" id="hap1" className='w-[300px] max-md:w-[150px]'/>
                <img src={hap2} alt="2" id="hap2" className='w-[300px] max-md:w-[150px]'/>
              </div>
              <div className={currentTag === 'COM' ? 'animate' : ''} >
                <img src={hap3} alt="3" id="hap3" className='w-[512px]'/>
              </div>
            </div>
            
          </>
        }
        {/* <div className="item-left">
            <h2>
              다양한 <span className="textorange">레퍼런스</span>를 조합해
            </h2>
            <h2>
              <span className="textorange">독창적인 디자인</span>을 만들어 봐요!
            </h2>
          </div>
          <div className="item-right">
            <div className={`sources ${section2Visible ? 'animate' : ''}`}>
              <img src={hap1} alt="1" id="hap1" />
              <img src={hap2} alt="2" id="hap2" />
            </div>
						<div className={section2Visible ? 'animate' : ''} >
            	<img src={hap3} alt="3" id="hap3"/>
						</div>
          </div> */}
      </div>
  );
};

export {Container, LandingContainer};