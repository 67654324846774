import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/ConceptResult.css';
import config from '../config';
import { useCredit } from '../context/CreditContext';
import { GradientOutlineButton, OutlineButton } from '../components/Buttons';
import { FaCoins } from 'react-icons/fa';
import LoadingModal from '../components/LoadingModal';
import Alerts from '../Alerts';
import { handleAxios } from '../components/modules/AxiosModule';;

const UniteResult = () => {
  const { checkCredits, fetchCredits } = useCredit();
  const location = useLocation();
  const imageData = location.state?.imageData || [];
  const requestData = location.state?.requestData || null;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // 이미지 데이터 없으면 메인으로
  useEffect(() => {
    if (!location.state || imageData.length === 0) {
      navigate('/');
    }
  }, [navigate, location.state, imageData.length]);

  // 뒤로가기 방지  
  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate('/');
    };

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  // 복사하기
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(imageData);
      Alerts.copySuccess();
    } catch (err) {
      console.error('Failed to copy image URL to clipboard:', err);
      Alerts.copySuccess();
    }
  };
  
  // 다운로드
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = imageData;
    link.download = 'downloaded_image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // 같은 데이터로 재생성
  const handleRegenerate = async () => {
    const creditsAvailable = checkCredits(2);
    if (!creditsAvailable) return;
    if (requestData) {
      const generateTimestamp = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const date = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        return `${year}${month}${date}${hours}${minutes}${seconds}`;
      };

      const timestamp = generateTimestamp();
      const newOrderId = `${requestData.creator_sub}_${timestamp}`;

      const newRequestData = {
        ...requestData,
        orderId: newOrderId,
      };

      setIsLoading(true); // 로딩 시작
      const apiUrl = config.CONCEPT_GEN_URL;
      try {
        const response = await handleAxios("post", apiUrl, newRequestData);

        if (Array.isArray(response.data) && response.data.length === 0) {
          Alerts.NSFW();
          return;
        } else if (response.data) {
          await fetchCredits();
          navigate('/conceptresult', { state: { imageData: response.data, requestData: newRequestData } });
        } else if (response.status === 400 && response.data.detail === "Not enough credits") {
          Alerts.noCredits();
          navigate('/pricing');
        } else if (response.data && response.data.message) {
          alert(response.data.message);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          if (error.response.data.detail === "Not enough credits") {
            Alerts.noCredits()
            navigate('/pricing');
          } else {
            Alerts.invalidRequest(error);
          }
        } else {
          Alerts.generateFailed();
        }
      } finally {
        setIsLoading(false); // 로딩 종료
      }
    }
  };

  return (
    <div className='flex flex-col items-center justify-start min-h-screen px-4 mt-[100px]'>
      {isLoading && (
        <LoadingModal />
      )}

      <h1 className="text-4xl leading-snug text-center mt-6 text-white">로보브러시가 컨셉 이미지를 완성했어요!</h1>
      <h2 className="text-2xl leading-snug text-center mt-4 text-white">URL 복사하기를 눌러서 친구들에게 자랑해봐요.</h2>
  
      <div className="w-full max-w-[512px] h-auto aspect-square bg-gray-200 mb-4 mt-[20px]">
        <img src={imageData} alt="Selected" className="w-full h-full object-contain" />
      </div>

      <div className="grid grid-cols-2 gap-4 mt-[20px]">
        <OutlineButton text="URL복사하기" handleClick={handleCopy} id='concept-result-URLCopy' />
        <OutlineButton text="다운로드" handleClick={handleDownload} id='concept-result-download' />
        <OutlineButton text="돌아가기" handleClick={() => navigate('/conceptlanding')} id='concept-result-backToFirst' />
        <GradientOutlineButton text="재생성" handleClick={handleRegenerate} leftIcon={<FaCoins className="text-[16px] sm:text-[24px] align-middle" />} id='concept-result-regenerate' />
      </div>
    </div>
  );
}

export default UniteResult;