import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import Alerts from '../Alerts';
import { handleAxios } from '../components/modules/AxiosModule';

const ImageHistory = ({ currentUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) return;
  
    const fetchUserHistory = async () => {
      setIsLoading(true);
  
      try {
        const storedToken = JSON.parse(localStorage.getItem('token'));
        const apiURL = config.USER_GET_USER;
        if (storedToken && storedToken.token) {
          const response = await handleAxios("get", apiURL);

          if (Array.isArray(response.data)) {
            const filteredImages = response.data.filter(url => url.includes("COM") || url.includes("GEN"));
            setImages(filteredImages.reverse());
          }
        }
      } catch (error) {
        Alerts.loadingFailed(error)
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchUserHistory();
  }, [currentUser]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleImageClick = (image) => {
    const selectedHistory = {
      b64image: '',
      url: image
    }
    navigate('/mycanvas-result', { state: { image: selectedHistory } }); // result 페이지로 이미지 데이터를 전달하며 이동
  };

  const indexOfLastImage = currentPage * itemsPerPage;
  const indexOfFirstImage = indexOfLastImage - itemsPerPage;
  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  const totalPages = Math.ceil(images.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const pageRange = 1; // 현재 페이지 앞뒤로 보여줄 페이지 수

    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPages = [1];
      const endPages = [totalPages];

      for (let i = 0; i < startPages.length; i++) {
        pageNumbers.push(startPages[i]);
      }

      if (currentPage <= 3) {
        for (let i = 2; i <= 4; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
      } else {
        if (currentPage > pageRange + 2) {
          pageNumbers.push("...");
        }
        for (let i = Math.max(currentPage - pageRange, 3); i <= Math.min(currentPage + pageRange, totalPages - 1); i++) {
          pageNumbers.push(i);
        }
        if (currentPage < totalPages - pageRange - 1) {
          pageNumbers.push("...");
        }
      }

      for (let i = 0; i < endPages.length; i++) {
        pageNumbers.push(endPages[i]);
      }
    }

    return pageNumbers;
  };

  return (
    <>
      <h3 className="text-white text-[28px] font-bold mb-4">생성한 이미지 목록</h3>
      {isLoading ? (
        <p className='text-white text-[20px'>Loading...</p>
      ) : (
        <div>
          <div className="grid grid-cols-2 sm:grid-cols-5 gap-1">
            {currentImages.map((image, index) => (
              <div
                key={index}
                className="p-2 cursor-pointer"
                onClick={() => handleImageClick(image)}
              >
                <img src={image} alt={`img-${index}`} className="w-full h-auto rounded-md shadow-lg" />
              </div>
            ))}
          </div>
          <div className='flex justify-center mt-5 mb-12'>
            {renderPageNumbers().map((number, index) => (
              <button
                key={index}
                onClick={() => typeof number === 'number' && handlePageChange(number)}
                className={(number === currentPage ? 'bg-blue-500 text-white' : 'bg-transparant text-white')
                        + ' mx-1 my-1 px-4 py-2 rounded-md font-bold text-base cursor-pointer'
                        + ' max-lg:p-2'
                }
                disabled={number === "..."}
              >
                {number}
              </button>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default ImageHistory;