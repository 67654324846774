import React, { useEffect, useRef, useState } from 'react';
import { InputSearch } from './Inputs';
import useOutsideClick from './customHooks/useOutsideClick';
import '../styles/ControlPanel.css';
import axios from 'axios';
import SearchResult from './SearchResult';
import Alerts from '../Alerts';
// import config from '../config'

const ControlPanel = ({ setImageData, setIsLoading, isPanelActive, setIsPanelActive, inputRef }) => {
  const [prompt, setPrompt] = useState('');
  const [searchMode, setSearchMode] = useState(false);

  const genURL = process.env.REACT_APP_PROMPT_GEN_URL;

  const [searchedImages, setSearchedImages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalHits, setTotalHits] = useState(0);
  const [selectedImages, setSelectedImages] = useState([]);
  const resultsRef = useRef(null);
  const apiKey = process.env.REACT_APP_PIXABAY_API_KEY;
  const panelRef = useRef(null);

  // 컴포넌트 마운트 시 검색 모드로 설정 (생성 모드 미지원)
  useEffect(() => {
    setSearchMode(true);
  }, []);

  // 생성모드 검색 모드에 따른 submit 함수 구분
  const handleSubmit = (prompt) => {
    if (searchMode) {
      handleSearch(prompt);
    } else {
      handleGenerate(prompt);
    }
  };

  // 컨트롤 패널 외부 클릭 시 패널 비활성화
  useOutsideClick(panelRef, () => {
    if (searchedImages.length > 0) {
      setSearchedImages([]);
      setTotalHits(0);
      setCurrentPage(1);
      setPrompt('');
    }
    setIsPanelActive(false);
  });

  const buttonText = searchMode ? '검색하기' : '생성하기';
  const placeholder = searchMode ? '이미지를 검색해주세요.' : '생성할 이미지에 대한 설명을 입력해주세요.';

  // 이미지 생성 함수
  const handleGenerate = async (prompt) => {
    setIsLoading(true);
    try {
      const response = await axios.get(genURL, {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': '69420',
        },
        params: {
          prompt: prompt,
        },
      });

      if (response.data) {
        // console.log(response.data);
        setImageData(response.data);
      } else if (response.data && response.data.message) {
        alert(response.data.message);
      }
    } catch (error) {
      Alerts.generateFailed()
    } finally {
      setIsLoading(false);
    }
  };

  // 이미지 검색
  const handleSearch = async (prompt) => {
    const searchUrl = `https://pixabay.com/api/?key=${apiKey}&q=${encodeURIComponent(prompt)}&page=1&per_page=20`;
    try {
      const response = await axios.get(searchUrl);
      setSearchedImages(response.data.hits);
      setTotalHits(response.data.totalHits);
      setCurrentPage(1);
      resultsRef.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.error('Search error:', error);
    }
  };

  // 로드 모어
  const loadMoreImages = async () => {
    if (searchedImages.length >= totalHits) return;
    const newPage = currentPage + 1;
    const searchUrl = `https://pixabay.com/api/?key=${apiKey}&q=${encodeURIComponent(prompt)}&page=${newPage}&per_page=20`;
    try {
      const response = await axios.get(searchUrl);
      setSearchedImages(prevImages => [...prevImages, ...response.data.hits]);
      setCurrentPage(newPage);
    } catch (error) {
      console.error('Error loading more images:', error);
    }
  };

  // 이미지 선택 함수
  const handleImageSelect = (image) => {
    setSelectedImages([image]);
    setImageData(image.largeImageURL);
    setSearchedImages([]);
    setTotalHits(0);
    setCurrentPage(1);
    setIsPanelActive(false);
  };

  return (
    <div className='ControlPanel' ref={panelRef}>
      <div className='whatbar'></div>

      <div className='inputArea'>
        <InputSearch
          prompt={prompt}
          setPrompt={setPrompt}
          handleSubmit={handleSubmit}
          buttonText={buttonText}
          placeholder={placeholder}
          onFocus={() => setIsPanelActive(true)}
          inputRef={inputRef}
          btnId='concept-control-search'
        />
      </div>

      {searchedImages.length === 0 && isPanelActive && (
        <div className='text'>
          <h2>검색 엔진을 통해 배경으로 사용할 이미지를 검색해보세요.</h2>
        </div>
      )}

      {searchedImages.length > 0 && (
        <div className='searchResult' ref={resultsRef}>
          <SearchResult
            images={searchedImages}
            onImageSelect={handleImageSelect}
            loadMoreImages={loadMoreImages}
            totalHits={totalHits}
            selectedImages={selectedImages}
          />
        </div>
      )}
    </div>
  );
};

export default ControlPanel;