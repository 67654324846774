import React, { createContext, useContext, useState } from 'react';

// Context 생성
const ImgContext = createContext();

const ImgProvider = ({ children }) => {
  const [images, setImages] = useState([]);
  return (
    <ImgContext.Provider value={{ images, setImages }}>
      {children}
    </ImgContext.Provider>
  );
};

// Custom hook to use the Wand context
const useImages = () => {
  return useContext(ImgContext);
};

export { ImgProvider, useImages };
