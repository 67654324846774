import React from 'react'

const IphoneWarning = () => {
  //URL 복사
  const copyUrlToClipboard = () => {
    const url = 'https://robobrush.ai';
    navigator.clipboard.writeText(url).then(() => {
      alert('URL이 복사되었습니다!');
    }).catch(err => {
      console.error('URL 복사에 실패했습니다: ', err);
    });
  };

  return (
    <div class="w-screen h-screen flex flex-col text-center justify-start box-border p-6 px-2">
      <div className="mb-[20px]" >
        <p className="font-extrabold bg-gradient-to-r from-blue-500 to-purple-600 
                      bg-clip-text text-transparent inline-block text-4xl">ROBOBRUSH</p>
      </div>

      <h2 className="text-gray-200 text-sm leading-snug break-keep">iOS 정책상 인앱 브라우저 호환문제가 있습니다.</h2>
      <h2 className="text-gray-200 text-sm leading-snug break-keep">원활한 서비스를 위해 Chrome이나 Safari로 접속해주세요.</h2>

      <div class="flex flex-col bg-gray-700 bg-opacity-30 rounded-2xl items-center text-center justify-center w-[95%] max-w-[600px] box-border p-5 pt-5 pb-10 my-8 mx-auto">
        <h3 class="text-[#daa520] text-xl mb-5 break-keep">Step 1.</h3>
        <h4 class="text-gray-300 text-sm leading-relaxed break-keep">아래 버튼을 클릭하여 URL을 복사해주세요.</h4>
        <button class="text-gray-300 border border-white bg-transparent px-5 py-2 rounded-xl text-sm my-6 hover:text-[#daa520] hover:border-[#daa520] cursor-pointer"
          onClick={copyUrlToClipboard}>URL 복사하기</button>

        <h3 class="text-[daa520] text-xl mb-5 break-keep">Step 2.</h3>
        <h4 class="text-gray-300 text-sm leading-relaxed break-keep">Chrome 혹은 Safari 브라우저를 열고 복사된 URL을 주소창에 붙여넣어주세요.</h4>
      </div>
    </div>
  )
}

export default IphoneWarning