import React, { createContext, useContext, useState } from 'react';

// Context 생성
const TagContext = createContext();

const TagProvider = ({ children }) => {
  const [currentTag, setCurrentTag] = useState('GEN');
  return (
    <TagContext.Provider value={{ currentTag, setCurrentTag }}>
      {children}
    </TagContext.Provider>
  );
};

// Custom hook to use the Wand context
const useTag = () => {
  return useContext(TagContext);
};

export { TagProvider, useTag };
