import React, { useEffect } from 'react';
import axios from 'axios';
import config from '../config';
import { useCredit } from '../context/CreditContext';

const PaymentResult = () => {
  // 포트원 모바일 결제시 리디렉션 될 페이지
  const { fetchCredits } = useCredit()
  
  const urlParams = new URLSearchParams(window.location.search);
  const paymentId = urlParams.get('paymentId');

  // 쿼리 데이터 읽어서 결제 검증 서버 호출
  useEffect(() => {
    if (paymentId) {
      axios.post(config.PORTONE_CHECK_VAL, {
        paymentId: paymentId,
      })
      .then(response => {
        const data = response.data;
        if (data.status) {
          // console.log('결제 검증 성공:', data.status);
          alert(`결제 성공. ${data.status}`);
          window.location.href = '/#/';
          fetchCredits()
        } else {
          // console.log('결제 검증 실패:', data.message);
          alert(`결제 검증 실패: ${data.message}`);
          window.location.href = '/#/';
        }
      })
      .catch(error => {
        console.error('결제 검증 요청 오류:', error);
        alert(`결제 검증 요청 오류: ${error.response.data.detail}`);
        window.location.href = '/#/';
      });
    } else {
      console.error('결제 정보가 누락되었습니다.');
      alert('결제 정보가 누락됐습니다. 다시 시도해주세요.')
      window.location.href = '/#/pricing';
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ marginTop: 150, textAlign: 'center', color: 'white'}}>
      <h1>Checking Payment Result...</h1>
      <p>결제 결과를 확인 중입니다...</p>
      <div>{paymentId}</div>
    </div>
  );
}

export default PaymentResult;
