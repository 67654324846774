import React from 'react';
import '../styles/ConfirmModal.css'

const ConfirmModal = ({ isOpen, setIsOpen, message, message1, message2, message3, onClose, onConfirm, marginTop, marginTop2, yesButtonId, noButtonId }) => {
  const Confirm = () => {
    onConfirm()
    setIsOpen(false)
  }

  const divStyle = {
    marginTop: marginTop
  };

  const divStyle2 = {
    marginTop: marginTop2
  };

  if (!isOpen) return null;

  return (
    <div className='ConfirmModal'>
      <div className='ContentBox'>
        <h2>{message}</h2>
        {message1 && <h2>{message1}</h2>}
        <div style={divStyle}></div>
        {message2 && <h2>{message2}</h2>}
        {message3 && <h3>{message3}</h3>}
        <div style={divStyle2}></div>
        <div>
          <button id={noButtonId} onClick={onClose}>아니오</button>
          <button id={yesButtonId} onClick={Confirm} style={{ marginLeft: '30px' }}>예</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
