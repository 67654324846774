import React from 'react';
import { useNavigate } from 'react-router-dom';
import { GradientOutlineButton, OutlineButton } from '../components/Buttons';
import { handleAxios } from '../components/modules/AxiosModule';
import config from '../config';
import LoadingModal from './LoadingModal';
import { FaCoins } from 'react-icons/fa';
import Alerts from '../Alerts';

const ResultHistory = ({ image, checkCredits, loginCheck, fetchCredits, isLoading, setIsLoading }) => {
  const navigate = useNavigate();
  const ReGenApiURL = config.HISTORY_REGEN_APIURL;

  // 재생성 요청 for 이미지 리스트 클릭으로 들어온 경우
  const handleHistoryRegenerate = async () => {
    loginCheck()
    if (!loginCheck()) return;
    const creditsAvailable = checkCredits(1);
    if (!creditsAvailable) return;

    setIsLoading(true);
    
    try {
      const requestData = {
        image_url: image.url,
      }
      const response = await handleAxios("post", ReGenApiURL, requestData);
      
      if (response.data) {
        await fetchCredits();
        navigate('/mycanvas-result', { state: { image: response.data } });
      } else if (response.data && response.data.message) {
        alert(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        if (error.response.data.detail === "Not enough credits") {
          Alerts.noCredits(error)
          navigate('/pricing');
        } else {
          Alerts.loadingFailed(error)
        }
      } else {
        Alerts.loadingFailed(error)
      }
    } finally {
      setIsLoading(false);
    }
  };

  // URL 복사
  const handleCopy = () => {
    navigator.clipboard.writeText(image.url).then(() => {
      Alerts.copySuccess()
    }).catch((error) => {
      Alerts.copyFailed(error)
    });
  };

  // 다운로드
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = image.url;
    link.download = 'image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // 편집 시작하기 시 url to b64 for edit
  const handleEditStart = async () => {
    // 사용자 디바이스가 모바일인지 확인
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      Alerts.onlyDesktop()
    }

    const endPoint = config.HISTORY_URL_TO_B64_URL;
    try {
      setIsLoading(true);

      let base64Data;
      if (image.b64image) {
        // image 데이터 안에 b64image가 존재할 경우
        base64Data = image.b64image;
      } else {
        // b64image가 없을 경우 기존 방식으로 처리
        const requestData = {
          image_url: image.url,
        };
        const response = await handleAxios("post", endPoint, requestData);

        base64Data = response.data;
      }

      navigate('/mycanvas-edit', { state: { image: {b64image: base64Data, url: image.url} }});
    } catch (error) {
      Alerts.imgLoadFailed(error)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start px-4 mt-20">
      {isLoading && <LoadingModal />}
      <div className="w-full max-w-[660px] h-auto aspect-square bg-gray-200 mb-4">
        <img src={image.url} alt="Selected" className="w-full h-full object-contain" />
      </div>
      {!isLoading && (
        <div className="grid grid-cols-2 gap-4 sm:w-[auto] w-[90%]">
          <GradientOutlineButton text="편집 시작하기" handleClick={handleEditStart} />
          <GradientOutlineButton text="다시 생성하기" leftIcon={<FaCoins className="text-[14px] sm:text-[24px] align-middle" />} handleClick={handleHistoryRegenerate} />
          <OutlineButton text="복사하기" handleClick={handleCopy} />
          <OutlineButton text="다운로드" handleClick={handleDownload} />
        </div>
      )}
    </div>
  );
};

export default ResultHistory;