import axios from 'axios';

async function handleAxios(type, apiUrl, requestData=null) {
  const storedToken = JSON.parse(localStorage.getItem('token'));

  const headers = {
    'Content-Type': 'application/json',
    'ngrok-skip-browser-warning': 'true',
  };

  if (storedToken) headers['Authorization']=`Bearer ${storedToken.token}`;

  if (type === "post") {

    const response = await axios.post(apiUrl, requestData, {
      headers: headers,
    });

    return response;
  }
  else if(type === "get") {

    const response = await axios.get(apiUrl, {
      headers: headers,
    });

    return response;
  }
  else if(type === "put") {

    const response = await axios.put(apiUrl, requestData, {
      headers: headers,
    });

    return response;
  }
  else if(type === "delete") {
    headers['Content-Type'] = 'application/json;charset=UTF-8';
    await axios.delete(apiUrl, {
      headers: headers,
    });

    return;
  }
}
  
export { handleAxios }
